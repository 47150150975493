import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { format as formatDate } from "date-fns";

import * as AccountsApis from "apis/accounts";

export default function useRecordUpdate({ account, onCreateSuccess }) {
  const form = useForm({
    defaultValues: {
      name: account.name,
      currentValue: account.currentValue,
      date: formatDate(new Date(), "dd/MM/yyyy"),
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      console.log(data, "save data!");
      try {
        const updatedAccount = await AccountsApis.recordEntry(account.id, {
          currentValue: data.currentValue,
          date: data.date,
        });

        if (typeof onCreateSuccess === "function") {
          onCreateSuccess(updatedAccount);
        }
      } catch (err) {
        console.log(err, "erorr!");
      }
    },
    [onCreateSuccess, account]
  );

  return {
    form,
    handleSubmit: form.handleSubmit(onSubmit),
  };
}

import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { format as formatDate } from "date-fns";

// import { useAuthProvider } from "components/AuthProvider";
import * as InvestmentsApis from "apis/investments";

export default function useRecordUpdate({ investment, onCreateSuccess }) {
  const form = useForm({
    defaultValues: {
      name: investment.name,
      invested: investment.invested,
      currentValue: investment.currentValue,
      liquidity: investment.liquidity,
      date: formatDate(new Date(), "dd/MM/yyyy"),
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      try {
        const updatedInvestment = await InvestmentsApis.recordEntry(
          investment.id,
          {
            invested: data.invested,
            currentValue: data.currentValue,
            liquidity: data.liquidity,
            date: data.date,
          }
        );

        if (typeof onCreateSuccess === "function") {
          onCreateSuccess(updatedInvestment);
        }
      } catch (err) {
        console.log(err, "erorr!");
      }
    },
    [onCreateSuccess, investment]
  );

  return {
    form,
    handleSubmit: form.handleSubmit(onSubmit),
  };
}

import classNames from "classnames";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

import { CURRENCIES } from "constants/currencies";

export default function Input({
  field: { onChange, onBlur, name, value, ref },
  fieldState: { invalid, isTouched, isDirty },
  formState: { errors },
  type,
  className,
  ...rest
}) {
  value = value || { currency: "INR", value: "" };

  const isInvalid = invalid && isTouched;
  const errorMessage = errors[name]?.message;

  return (
    <div>
      <div className="relative shadow-sm">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <span className="text-gray-500 sm:text-sm">
            {CURRENCIES.filter((c) => c.value === value.currency)[0].symbol}
          </span>
        </div>
        <input
          name={name}
          type={type || "text"}
          className={classNames(
            "block w-full pl-7 pr-12 py-2 appearance-none hideArrow focus:outline-none sm:text-sm rounded-md",
            className,
            {
              "pr-10 text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500":
                isInvalid,
              "placeholder-gray-400 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 ":
                !isInvalid,
            }
          )}
          aria-invalid={isInvalid}
          ref={ref}
          value={value.value}
          onChange={(v) => onChange({ ...value, value: v.target.value })}
          onBlur={onBlur}
          {...rest}
        />
        {isInvalid && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-16 pointer-events-none">
            <ExclamationCircleIcon
              className="w-5 h-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
        <div className="absolute inset-y-0 right-0 flex items-center">
          <label htmlFor="currency" className="sr-only">
            Currency
          </label>
          <select
            className="h-full py-0 pl-2 pr-6 text-gray-500 bg-transparent border-transparent rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={value.currency}
            onChange={(v) => onChange({ value: "", currency: v.target.value })}
          >
            {CURRENCIES.map((cur) => (
              <option key={cur.value} value={cur.value}>
                {cur.value}
              </option>
            ))}
          </select>
        </div>
      </div>
      {errorMessage && (
        <p className="mt-1 text-xs text-red-600">{errorMessage}</p>
      )}
    </div>
  );
}

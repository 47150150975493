import { useState } from "react";
import {
  endOfYear,
  startOfMonth,
  startOfYear,
  subDays,
  subYears,
} from "date-fns";

import * as Accountant from "services/accountant";
import useExpenses from "hooks/data/useExpenses";
import useIncomes from "hooks/data/useIncomes";
import useInvestments from "hooks/data/useInvestments";
import useAccounts from "hooks/data/useAccounts";

const TODAY = new Date();

export default function useCashflow() {
  const [statsTimeRange, setStatsTimeRange] = useState({
    start: startOfMonth(TODAY),
    end: TODAY,
  });
  const [monthlySeriesTimeRange, setMonthlySeriesTimeRange] = useState(
    MONTHLY_SERIES_TIME_RANGE_OPTIONS[1]
  );

  const { expenses, loading: expensesLoading } = useExpenses();
  const { incomes, loading: incomesLoading } = useIncomes();
  const { accounts, loading: accountsLoading } = useAccounts();
  const { investments, loading: investmentsLoading } = useInvestments();

  const loading =
    expensesLoading || incomesLoading || accountsLoading || investmentsLoading;

  return {
    statsTimeRange,
    setStatsTimeRange,

    monthlySeriesTimeRange,
    setMonthlySeriesTimeRange,

    loading,
    error: false,
    statsData: !loading
      ? Accountant.cashflowData(
          { expenses, incomes, accounts, investments },
          statsTimeRange
        )
      : null,

    monthlySeriesData: !loading
      ? Accountant.cashflowSeries(
          { expenses, incomes, accounts, investments },
          monthlySeriesTimeRange
        )
      : null,
  };
}

export const MONTHLY_SERIES_TIME_RANGE_OPTIONS = [
  {
    label: "Last 90 days",
    value: "90days",
    start: subDays(TODAY, 90),
    end: TODAY,
  },
  {
    label: "Last 180 days",
    value: "180days",
    start: subDays(TODAY, 180),
    end: TODAY,
  },
  {
    label: "Last 365 days",
    value: "365days",
    start: subDays(TODAY, 365),
    end: TODAY,
  },
  {
    label: "This year",
    value: "thisYear",
    start: startOfYear(TODAY),
    end: TODAY,
  },
  {
    label: "Last year",
    value: "lastYear",
    start: startOfYear(subYears(TODAY, 1)),
    end: endOfYear(subYears(TODAY, 1)),
  },
];

import React, { useState } from "react";
import { FolderAddIcon } from "@heroicons/react/outline";
import { PlusCircleIcon as PlusCircleIconSolid } from "@heroicons/react/solid";

import Button, { TextButton } from "components/Button";
import Modal from "components/Modal";
import Loaders from "components/Loaders";
import useIncomes from "hooks/data/useIncomes";

import IncomesList from "./IncomesList";
import CreateIncomeModal from "./CreateIncomeModal";

export default function IncomesCard() {
  const [showNewIncomeModal, setShowNewIncomeModal] = useState(false);
  const { incomes, loading, error } = useIncomes();

  return (
    <>
      <div className="flex-col overflow-hidden bg-white border rounded-sm shadow-sm border-gray-50">
        <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
          <div className="flex flex-wrap items-center justify-between -mt-2 -ml-4 sm:flex-nowrap">
            <div className="mt-2 ml-4">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Incomes
              </h3>
            </div>
            <div className="flex-shrink-0 mt-2 ml-4">
              <Button
                onClick={() => setShowNewIncomeModal(true)}
                variant="primaryGreen"
              >
                <PlusCircleIconSolid className="self-center w-4 h-4 mr-2 text-white" />
                Add
              </Button>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="flex justify-center py-12">
            <Loaders.Spinner className="w-6 h-6 text-indigo-600" />
          </div>
        ) : incomes && incomes.length ? (
          <IncomesList incomes={incomes} />
        ) : (
          <div className="flex-col py-12 text-center">
            <FolderAddIcon className="w-10 h-10 mx-auto text-gray-400" />
            <h3 className="mt-2 text-lg font-medium text-gray-900">
              No incomes recorded
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              Add your incomes here to keep track of net monthly cashflow
            </p>
            <p className="text-sm text-gray-500">
              <TextButton onClick={() => setShowNewIncomeModal(true)}>
                Record first income
              </TextButton>{" "}
              to get started.
            </p>
          </div>
        )}
      </div>
      <Modal
        open={showNewIncomeModal}
        onClose={() => setShowNewIncomeModal(false)}
        content={
          <CreateIncomeModal
            incomes={incomes || []}
            close={() => setShowNewIncomeModal(false)}
          />
        }
      />
    </>
  );
}

import React from "react";
import { useParams, Navigate } from "react-router-dom";

import Loaders from "components/Loaders";

import Header from "./Header";
// import StatsCard from "./StatsCard";
import EntriesCard from "./EntriesCard";
import useAccount from "./hooks/useAccount";

export default function Account(props) {
  const { accountId } = useParams();
  const { value: account, loading } = useAccount({ id: accountId });

  return (
    <div className="flex flex-col pb-12">
      {loading ? (
        <Loaders.Spinner className="w-6 h-6 my-6 text-indigo-600" />
      ) : account ? (
        <div className="flex flex-col space-y-6">
          <Header account={account} />
          {account.entries ? <EntriesCard account={account} /> : null}
        </div>
      ) : (
        <Navigate to="/" replace={true} />
      )}
    </div>
  );
}

import classNames from "classnames";
import Loaders from "components/Loaders";

export default function Button({
  variant = "primary",
  size = "regular",
  minWidth,
  children,
  className,
  loading,
  loaderPosition = "left",
  ...rest
}) {
  const style = rest.style || {};
  if (minWidth) {
    style.minWidth = minWidth;
  }

  return (
    <button
      className={classNames(
        "flex justify-center w-full font-medium px-2.5 py-1.5 sm:px-4 sm:py-2 text-xs sm:text-sm border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 relative",
        className,
        VARIANT_TO_CLASS_MAP[variant]
      )}
      {...rest}
      disabled={rest.disabled || loading}
      style={style}
    >
      {children}
      {loading ? (
        <Loaders.Spinner
          className={classNames(
            "absolute w-5 h-5 text-white",
            `${loaderPosition}-2`
          )}
        />
      ) : null}
    </button>
  );
}

const VARIANT_TO_CLASS_MAP = {
  primary:
    "text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 disabled:bg-gray-600 disabled:opacity-75",
  primaryRed:
    "text-white bg-red-700 hover:bg-red-800 focus:ring-red-500 disabled:bg-gray-600 disabled:opacity-75",
  primaryGreen:
    "text-white bg-green-700 hover:bg-green-700 focus:ring-green-500 disabled:bg-gray-600 disabled:opacity-75",
  secondary:
    "text-gray-700 bg-white hover:bg-gray-50 focus:ring-indigo-500 border border-gray-300",
  secondaryRed:
    "text-red-600 bg-white hover:bg-red-50 focus:ring-red-400 border border-red-500",
  secondaryGreen:
    "text-green-600 bg-white hover:bg-green-50 focus:ring-green-400 border border-green-500",
};

export const TextButton = ({ variant = "primary", children, ...rest }) => {
  return (
    <button
      className={classNames(
        "shadow-none outline-none bg-none underline",
        TEXTBUTTON_VARIANT_TO_CLASS_MAP[variant]
      )}
      {...rest}
    >
      {children}
    </button>
  );
};

const TEXTBUTTON_VARIANT_TO_CLASS_MAP = {
  primary: "text-indigo-600 hover:text-indigo-700",
  secondary: "text-red-600 hover:text-red-700",
};

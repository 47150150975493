import { collection, query, where } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";

import { firebaseStore } from "services/firebase";
import { useAuthProvider } from "components/AuthProvider";

export default function useUserCollectionData(collectionName, options = {}) {
  const authProvider = useAuthProvider();
  const [data, loading, error] = useCollectionData(
    query(
      collection(firebaseStore, collectionName),
      where("userId", "==", authProvider.currentUser.uid)
    ),
    {
      idField: "id",
    }
  );

  return {
    data,
    loading,
    error,
  };
}

import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";

import { firebaseStore } from "services/firebase";

export default function useAccount({ id }) {
  const [value, loading, error] = useDocumentData(
    doc(firebaseStore, "accounts", id)
  );

  if (value) {
    value.id = value.id || id;
  } else if (error || !loading) {
    // TODO: Display toast that record was not found
  }

  return { value, loading, error };
}

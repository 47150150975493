import { useCallback } from "react";
import { useForm } from "react-hook-form";

import * as AccountApis from "apis/accounts";

export default function useEditAccount({ account, onCreateSuccess }) {
  const form = useForm({
    defaultValues: {
      name: account.name,
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      try {
        const updatedInvestment = await AccountApis.update(account.id, {
          name: data.name,
        });

        if (typeof onCreateSuccess === "function") {
          onCreateSuccess(updatedInvestment);
        }
      } catch (err) {
        console.log(err, "erorr!");
      }
    },
    [onCreateSuccess, account]
  );

  return {
    form,
    handleSubmit: form.handleSubmit(onSubmit),
  };
}

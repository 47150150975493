import {
  addDoc,
  deleteDoc,
  collection,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { format as formatDate, parse as parseDate } from "date-fns";

import { firebaseStore } from "services/firebase";
import { _uniqBy } from "services/lodash";

export async function create(data) {
  const collectionRef = collection(firebaseStore, "accounts");

  if (!data.entries) {
    data.entries = [buildEntryFromAccountData(data)];
  }

  return await addDoc(collectionRef, data);
}

export async function update(accountId, data) {
  const docRef = doc(firebaseStore, "accounts", accountId);
  return await setDoc(docRef, data, { merge: true });
}

export async function remove(accountId) {
  const docRef = doc(firebaseStore, "accounts", accountId);
  return await deleteDoc(docRef);
}

export async function recordEntry(accountId, data) {
  const docRef = doc(firebaseStore, "accounts", accountId);
  const accountDoc = await getDoc(docRef);
  const account = accountDoc.data();
  const entry = buildEntryFromAccountData(data);

  let accountUpdate = data;
  if (account.entries && account.entries.length > 0) {
    accountUpdate = {
      ...accountUpdate,
      entries: _uniqBy([entry, ...account.entries], "date").sort(
        (e1, e2) => e2.timestamp - e1.timestamp
      ),
    };
  } else {
    accountUpdate = {
      ...accountUpdate,
      entries: [entry],
    };
  }

  return await setDoc(docRef, accountUpdate, { merge: true });
}

function buildEntryFromAccountData(data) {
  let date = new Date();
  if (data.date) {
    date = parseDate(data.date, "dd/MM/yyyyy", new Date());
  }

  return {
    currentValue: data.currentValue,
    timestamp: date.getTime(),
    date: formatDate(date, "dd/MM/yyyy"),
  };
}

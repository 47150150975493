import React, { useRef, useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { format as formatDate } from "date-fns";
import {
  SortAscendingIcon,
  SortDescendingIcon,
} from "@heroicons/react/outline";

import Currency from "components/Currency";
import { useAuthProvider } from "components/AuthProvider";
import * as Accountant from "services/accountant";
import * as Browser from "services/browser";
import useExpandList from "hooks/useExpandList";

const INITIAL_VISIBLE_ROWS_LENGTH = Browser.getScreen() === "mobile" ? 6 : 20;

export default function IncomesList({ incomes }) {
  const listRef = useRef(null);
  const navigate = useNavigate();
  const authProvider = useAuthProvider();
  const columns = useMemo(() => {
    return [
      {
        id: "date",
        Header: "Date",
        accessor: (row) => formatDate(new Date(row.timestamp), "MMM dd, yyyy"),
        sortDescFirst: true,
        sortType: (r1, r2) => r1.original.timestamp - r2.original.timestamp,
      },
      {
        accessor: "amount",
        Header: "Amount",
        Cell: ({ value }) => <Currency value={value} />,
        sortDescFirst: true,
        sortType: (r1, r2) =>
          Accountant.sortCurrency(r1.original.amount, r2.original.amount),
      },
      {
        accessor: "reason",
        Header: "Remarks",
        disableSortBy: true,
      },
    ];
  }, []);

  const sortBy = useMemo(() => {
    return [
      {
        id: authProvider.settings.cards.incomesList.sortKey,
        desc: authProvider.settings.cards.incomesList.sortDesc,
      },
    ];
  }, [authProvider.settings]);

  console.log({ incomes });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      data: incomes,
      columns,
      initialState: {
        sortBy,
        pageSize: INITIAL_VISIBLE_ROWS_LENGTH,
        pageIndex: 0,
      },
      disableSortRemove: true,
    },
    useSortBy,
    // useGroupBy,
    // useFilters,
    // useExpanded,
    usePagination
  );

  const expandList = useExpandList({
    visible: INITIAL_VISIBLE_ROWS_LENGTH < incomes.length,
    initialExpanded: false,
    onExpand: () => setPageSize(incomes.length + 1),
    onShrink: () => {
      setPageSize(INITIAL_VISIBLE_ROWS_LENGTH);
      requestAnimationFrame(() => {
        listRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
      });
    },
  });

  function onColumnSort({ id, desc }) {
    authProvider.updateSettings("cards.incomesList", {
      sortKey: id,
      sortDesc: desc,
    });
  }

  return (
    <div ref={listRef} className="relative">
      <div className="overflow-x-auto">
        <table
          className="min-w-full divide-y divide-gray-100"
          {...getTableProps()}
        >
          <thead className="bg-gray-50">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  const { onClick: originalOnClick, ...rest } =
                    column.getSortByToggleProps();

                  const columnSortByProps = {
                    ...rest,
                    onClick: (event) => {
                      if (typeof originalOnClick === "function") {
                        onColumnSort({
                          id: column.id,
                          desc: !column.isSortedDesc,
                        });
                        originalOnClick(event);
                      }
                    },
                  };

                  return (
                    <th
                      {...column.getHeaderProps(columnSortByProps)}
                      className={classNames(
                        "px-4 md:px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase",
                        { "hover:bg-gray-100": column.canSort }
                      )}
                      style={{
                        minWidth: 150,
                      }}
                    >
                      <span className="relative pr-6">
                        {column.render("Header")}
                        {column.canSort && (
                          <span className="absolute top-0 right-0">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <SortDescendingIcon className="w-3.5 h-3.5 text-gray-400" />
                              ) : (
                                <SortAscendingIcon className="w-3.5 h-3.5 text-gray-400" />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        )}
                      </span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={classNames(
                    "cursor-pointer hover:bg-gray-50 bg-white",
                    { "border-b border-gray-100": index < page.length - 1 }
                  )}
                  onClick={() => {
                    navigate(`/accounts/${row.original.id}`);
                  }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={
                          "align-top px-4 md:px-6 py-4 whitespace-nowrap"
                        }
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {expandList.visible ? (
        <div className="p-2 text-xs font-medium text-center text-gray-600 bg-gray-50">
          <span
            className="px-4 py-2 cursor-pointer"
            onClick={expandList.toggle}
          >
            {expandList.message}
          </span>
        </div>
      ) : null}
    </div>
  );
}

import { addDoc, collection, doc, setDoc, getDoc } from "firebase/firestore";
import { format as formatDate, parse as parseDate } from "date-fns";
import { firebaseStore } from "services/firebase";
import { _uniqBy } from "services/lodash";

export async function create(data) {
  const collectionRef = collection(firebaseStore, "investments");

  if (!data.entries) {
    data.entries = [buildEntryFromInvestmentData(data)];
  }

  return await addDoc(collectionRef, data);
}

export async function update(investmentId, data) {
  const docRef = doc(firebaseStore, "investments", investmentId);
  return await setDoc(docRef, data, { merge: true });
}

export async function recordEntry(investmentId, data) {
  const docRef = doc(firebaseStore, "investments", investmentId);
  const investmentDoc = await getDoc(docRef);
  const investment = investmentDoc.data();
  const entry = buildEntryFromInvestmentData(data);

  let investmentUpdate = data;
  if (investment.entries && investment.entries.length > 0) {
    investmentUpdate = {
      ...investmentUpdate,
      entries: _uniqBy([entry, ...investment.entries], "date").sort(
        (e1, e2) => e2.timestamp - e1.timestamp
      ),
    };
  } else {
    investmentUpdate = {
      ...investmentUpdate,
      entries: [entry],
    };
  }

  return await setDoc(docRef, investmentUpdate, { merge: true });
}

export async function remove() {}

function buildEntryFromInvestmentData(data) {
  let date = new Date();
  if (data.date) {
    date = parseDate(data.date, "dd/MM/yyyyy", new Date());
  }

  return {
    invested: data.invested,
    currentValue: data.currentValue,
    liquidity: data.liquidity,
    timestamp: date.getTime(),
    date: formatDate(date, "dd/MM/yyyy"),
  };
}

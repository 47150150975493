import { subMonths } from "date-fns";
import { useState } from "react";
import * as Accountant from "services/accountant";
import useExpenses from "hooks/data/useExpenses";
import useIncomes from "hooks/data/useIncomes";
import useInvestments from "hooks/data/useInvestments";
import useAccounts from "hooks/data/useAccounts";

const TODAY = new Date();

export default function useMonthlyCashflow() {
  const [timeRange, setTimeRange] = useState(TIME_RANGE_OPTIONS[0]);
  const { expenses, loading: expensesLoading } = useExpenses();
  const { incomes, loading: incomesLoading } = useIncomes();
  const { accounts, loading: accountsLoading } = useAccounts();
  const { investments, loading: investmentsLoading } = useInvestments();

  const loading =
    expensesLoading || incomesLoading || accountsLoading || investmentsLoading;

  return {
    timeRange,
    setTimeRange: (value) =>
      setTimeRange(TIME_RANGE_OPTIONS.filter((o) => o.value === value)[0]),
    loading,
    error: false,
    data: !loading
      ? Accountant.cashflowSeries(
          { expenses, incomes, accounts, investments },
          timeRange
        )
      : null,
  };
}

export const TIME_RANGE_OPTIONS = [
  {
    label: "Last 3 months",
    value: "3m",
    start: subMonths(TODAY, 3),
    end: TODAY,
  },
  {
    label: "Last 6 months",
    value: "6m",
    start: subMonths(TODAY, 6),
    end: TODAY,
  },
  {
    label: "Last 12 months",
    value: "12m",
    start: subMonths(TODAY, 12),
    end: TODAY,
  },
];

import React, { useState, useMemo } from "react";
import format from "date-fns/format";
import { Chart } from "react-charts";
import { FolderAddIcon } from "@heroicons/react/outline";
import { PlusCircleIcon as PlusCircleIconSolid } from "@heroicons/react/solid";

import Button, { TextButton } from "components/Button";
import Loaders from "components/Loaders";
import Currency from "components/Currency";
import { _find } from "services/lodash";

import useCashflow, {
  MONTHLY_SERIES_TIME_RANGE_OPTIONS,
} from "./hooks/useCashflow";

export default function CashflowCard() {
  const {
    statsData,
    statsTimeRange,
    monthlySeriesData,
    monthlySeriesTimeRange,
    setMonthlySeriesTimeRange,
    loading,
    error,
  } = useCashflow();

  return (
    <div className="flex-col overflow-hidden bg-white border rounded-sm shadow-sm border-gray-50">
      <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
        <div className="flex flex-wrap items-center justify-between -mt-2 -ml-4 sm:flex-nowrap">
          <div className="mt-2 ml-4">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Cashflow
            </h3>
          </div>
          {false && (
            <div className="flex-shrink-0 mt-2 ml-4">
              <Button onClick={() => {}}>
                <PlusCircleIconSolid className="self-center w-4 h-4 mr-2 text-white" />
                Add Account
              </Button>
            </div>
          )}
        </div>
      </div>
      {loading || !statsData ? (
        <div className="flex justify-center py-12">
          <Loaders.Spinner className="w-6 h-6 text-indigo-600" />
        </div>
      ) : (
        <React.Fragment>
          <ThisMonthStats timeRange={statsTimeRange} data={statsData} />
          <MonthlyStatsSeries
            timeRange={monthlySeriesTimeRange}
            setTimeRange={(value) =>
              setMonthlySeriesTimeRange(
                _find(MONTHLY_SERIES_TIME_RANGE_OPTIONS, { value })
              )
            }
            data={monthlySeriesData}
          />
        </React.Fragment>
      )}
    </div>
  );
}

function ThisMonthStats({ timeRange, data }) {
  return (
    <div className="flex-col px-6 py-6">
      <div className="mb-3 text-2xl font-medium">
        {timeRange ? format(timeRange.start, "MMM yyyy") : ""}
      </div>
      <dl className="text-sm divide-y divide-gray-50">
        <div className="flex items-center justify-between py-3">
          <dt className="text-gray-600">Income</dt>
          <dd className="font-medium text-green-600">
            <Currency value={data.income} />
          </dd>
        </div>
        <div className="flex items-center justify-between py-3">
          <dt className="text-gray-600">New Investments</dt>
          <dd className="font-medium text-yellow-600">
            <Currency value={data.investments.invested} />
          </dd>
        </div>
        <div className="flex items-center justify-between py-3">
          <dt className="text-gray-600">Expenses</dt>
          <dd className="font-medium text-red-700">
            <Currency value={data.expenses.accounted} />
          </dd>
        </div>
        {false && (
          <div className="flex items-center justify-between py-3">
            <dt className="text-base font-medium text-gray-900">Net Change</dt>
            <dd className="text-base font-medium text-gray-900">$320.40</dd>
          </div>
        )}
      </dl>
    </div>
  );
}
function MonthlyStatsSeries({ data, timeRange, setTimeRange }) {
  const primaryAxis = useMemo(
    () => ({
      getValue: (datum) => datum.primary,
    }),
    []
  );
  const secondaryAxes = useMemo(
    () => [
      {
        getValue: (datum) => datum.secondary,
      },
    ],
    []
  );

  return (
    <div className="flex-col py-6">
      <select
        id="monthlySeriesCashflowGroupBy"
        name="monthlySeriesCashflowGroupBy"
        className="mb-2 cursor-pointer block py-0.5 ml-auto mx-3 pr-8 text-sm border-none bg-transparent rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        value={timeRange.value}
        onChange={(e) => setTimeRange(e.target.value)}
      >
        {MONTHLY_SERIES_TIME_RANGE_OPTIONS.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="mx-6" style={{ minHeight: 200 }}>
        <Chart
          options={{
            data,
            primaryAxis,
            secondaryAxes,
          }}
        />
      </div>
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";

import FormField from "components/Form/Field";
import Button from "components/Button";

import useSignup from "./hooks/useSignup";

export default function SignupContainer() {
  const signup = useSignup();

  return (
    <>
      <div className="flex flex-col justify-center min-h-full py-12 bg-gray-100 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900">
            Create Account
          </h2>
        </div>

        <div className="flex flex-col items-center justify-center mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <span className="w-full px-4 mb-4 text-xs text-center md:text-sm md:px-0 md:text-left">
            Penny helps you to{" "}
            <strong>track all of your assets in one place</strong>, while giving
            you a <strong>bird's eye view of your finances</strong>. It also
            helps you <strong>calculate your projected net worth</strong> in
            various scenarios.
          </span>
          <div className="w-full px-6 py-8 bg-white shadow md:px-8 sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={signup.handleSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <div className="mt-1">
                  <FormField
                    name="email"
                    type="email"
                    controlType="input"
                    control={signup.form.control}
                    rules={{ required: true }}
                    autoComplete="email"
                    id="email"
                    placeholder="Enter email..."
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <FormField
                    name="password"
                    controlType="input"
                    control={signup.form.control}
                    rules={{ required: true }}
                    autoComplete="current-password"
                    id="password"
                    type="password"
                    placeholder="Enter password..."
                  />
                </div>
              </div>

              <div>
                <Button
                  type="submit"
                  disabled={signup.form.formState.isSubmitting}
                  loading={signup.form.formState.isSubmitting}
                  loaderPosition="right"
                >
                  Sign up
                </Button>
              </div>

              <div className="flex items-center justify-center md:justify-start">
                <span className="mr-2 text-sm font-medium text-gray-500">
                  Already have an account ?
                </span>
                <div className="text-sm">
                  <Link
                    to="/signin"
                    className="font-medium text-indigo-600 hover:text-indigo-500 hover:underline"
                  >
                    Sign in to account
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export const CURRENCIES = [
  { value: "AFA", label: "Afghan Afghani", symbol: "؋" },
  { value: "ALL", label: "Albanian Lek", symbol: "Lek" },
  { value: "DZD", label: "Algerian Dinar", symbol: "دج" },
  { value: "AOA", label: "Angolan Kwanza", symbol: "Kz" },
  { value: "ARS", label: "Argentine Peso", symbol: "$" },
  { value: "AMD", label: "Armenian Dram", symbol: "֏" },
  { value: "AWG", label: "Aruban Florin", symbol: "ƒ" },
  { value: "AUD", label: "Australian Dollar", symbol: "$" },
  { value: "AZN", label: "Azerbaijani Manat", symbol: "m" },
  { value: "BSD", label: "Bahamian Dollar", symbol: "B$" },
  { value: "BHD", label: "Bahraini Dinar", symbol: ".د.ب" },
  { value: "BDT", label: "Bangladeshi Taka", symbol: "৳" },
  { value: "BBD", label: "Barbadian Dollar", symbol: "Bds$" },
  { value: "BYR", label: "Belarusian Ruble", symbol: "Br" },
  { value: "BEF", label: "Belgian Franc", symbol: "fr" },
  { value: "BZD", label: "Belize Dollar", symbol: "$" },
  { value: "BMD", label: "Bermudan Dollar", symbol: "$" },
  { value: "BTN", label: "Bhutanese Ngultrum", symbol: "Nu." },
  { value: "BTC", label: "Bitcoin", symbol: "฿" },
  { value: "BOB", label: "Bolivian Boliviano", symbol: "Bs." },
  { value: "BAM", label: "Bosnia-Herzegovina Convertible Mark", symbol: "KM" },
  { value: "BWP", label: "Botswanan Pula", symbol: "P" },
  { value: "BRL", label: "Brazilian Real", symbol: "R$" },
  { value: "GBP", label: "British Pound Sterling", symbol: "£" },
  { value: "BND", label: "Brunei Dollar", symbol: "B$" },
  { value: "BGN", label: "Bulgarian Lev", symbol: "Лв." },
  { value: "BIF", label: "Burundian Franc", symbol: "FBu" },
  { value: "KHR", label: "Cambodian Riel", symbol: "KHR" },
  { value: "CAD", label: "Canadian Dollar", symbol: "$" },
  { value: "CVE", label: "Cape Verdean Escudo", symbol: "$" },
  { value: "KYD", label: "Cayman Islands Dollar", symbol: "$" },
  { value: "XOF", label: "CFA Franc BCEAO", symbol: "CFA" },
  { value: "XAF", label: "CFA Franc BEAC", symbol: "FCFA" },
  { value: "XPF", label: "CFP Franc", symbol: "₣" },
  { value: "CLP", label: "Chilean Peso", symbol: "$" },
  { value: "CNY", label: "Chinese Yuan", symbol: "¥" },
  { value: "COP", label: "Colombian Peso", symbol: "$" },
  { value: "KMF", label: "Comorian Franc", symbol: "CF" },
  { value: "CDF", label: "Congolese Franc", symbol: "FC" },
  { value: "CRC", label: "Costa Rican ColÃ³n", symbol: "₡" },
  { value: "HRK", label: "Croatian Kuna", symbol: "kn" },
  { value: "CUC", label: "Cuban Convertible Peso", symbol: "$, CUC" },
  { value: "CZK", label: "Czech Republic Koruna", symbol: "Kč" },
  { value: "DKK", label: "Danish Krone", symbol: "Kr." },
  { value: "DJF", label: "Djiboutian Franc", symbol: "Fdj" },
  { value: "DOP", label: "Dominican Peso", symbol: "$" },
  { value: "XCD", label: "East Caribbean Dollar", symbol: "$" },
  { value: "EGP", label: "Egyptian Pound", symbol: "ج.م" },
  { value: "ERN", label: "Eritrean Nakfa", symbol: "Nfk" },
  { value: "EEK", label: "Estonian Kroon", symbol: "kr" },
  { value: "ETB", label: "Ethiopian Birr", symbol: "Nkf" },
  { value: "EUR", label: "Euro", symbol: "€" },
  { value: "FKP", label: "Falkland Islands Pound", symbol: "£" },
  { value: "FJD", label: "Fijian Dollar", symbol: "FJ$" },
  { value: "GMD", label: "Gambian Dalasi", symbol: "D" },
  { value: "GEL", label: "Georgian Lari", symbol: "ლ" },
  { value: "DEM", label: "German Mark", symbol: "DM" },
  { value: "GHS", label: "Ghanaian Cedi", symbol: "GH₵" },
  { value: "GIP", label: "Gibraltar Pound", symbol: "£" },
  { value: "GRD", label: "Greek Drachma", symbol: "₯, Δρχ, Δρ" },
  { value: "GTQ", label: "Guatemalan Quetzal", symbol: "Q" },
  { value: "GNF", label: "Guinean Franc", symbol: "FG" },
  { value: "GYD", label: "Guyanaese Dollar", symbol: "$" },
  { value: "HTG", label: "Haitian Gourde", symbol: "G" },
  { value: "HNL", label: "Honduran Lempira", symbol: "L" },
  { value: "HKD", label: "Hong Kong Dollar", symbol: "$" },
  { value: "HUF", label: "Hungarian Forint", symbol: "Ft" },
  { value: "ISK", label: "Icelandic KrÃ³na", symbol: "kr" },
  { value: "INR", label: "Indian Rupee", symbol: "₹" },
  { value: "IDR", label: "Indonesian Rupiah", symbol: "Rp" },
  { value: "IRR", label: "Iranian Rial", symbol: "﷼" },
  { value: "IQD", label: "Iraqi Dinar", symbol: "د.ع" },
  { value: "ILS", label: "Israeli New Sheqel", symbol: "₪" },
  { value: "ITL", label: "Italian Lira", symbol: "L,£" },
  { value: "JMD", label: "Jamaican Dollar", symbol: "J$" },
  { value: "JPY", label: "Japanese Yen", symbol: "¥" },
  { value: "JOD", label: "Jordanian Dinar", symbol: "ا.د" },
  { value: "KZT", label: "Kazakhstani Tenge", symbol: "лв" },
  { value: "KES", label: "Kenyan Shilling", symbol: "KSh" },
  { value: "KWD", label: "Kuwaiti Dinar", symbol: "ك.د" },
  { value: "KGS", label: "Kyrgystani Som", symbol: "лв" },
  { value: "LAK", label: "Laotian Kip", symbol: "₭" },
  { value: "LVL", label: "Latvian Lats", symbol: "Ls" },
  { value: "LBP", label: "Lebanese Pound", symbol: "£" },
  { value: "LSL", label: "Lesotho Loti", symbol: "L" },
  { value: "LRD", label: "Liberian Dollar", symbol: "$" },
  { value: "LYD", label: "Libyan Dinar", symbol: "د.ل" },
  { value: "LTL", label: "Lithuanian Litas", symbol: "Lt" },
  { value: "MOP", label: "Macanese Pataca", symbol: "$" },
  { value: "MKD", label: "Macedonian Denar", symbol: "ден" },
  { value: "MGA", label: "Malagasy Ariary", symbol: "Ar" },
  { value: "MWK", label: "Malawian Kwacha", symbol: "MK" },
  { value: "MYR", label: "Malaysian Ringgit", symbol: "RM" },
  { value: "MVR", label: "Maldivian Rufiyaa", symbol: "Rf" },
  { value: "MRO", label: "Mauritanian Ouguiya", symbol: "MRU" },
  { value: "MUR", label: "Mauritian Rupee", symbol: "₨" },
  { value: "MXN", label: "Mexican Peso", symbol: "$" },
  { value: "MDL", label: "Moldovan Leu", symbol: "L" },
  { value: "MNT", label: "Mongolian Tugrik", symbol: "₮" },
  { value: "MAD", label: "Moroccan Dirham", symbol: "MAD" },
  { value: "MZM", label: "Mozambican Metical", symbol: "MT" },
  { value: "MMK", label: "Myanmar Kyat", symbol: "K" },
  { value: "NAD", label: "Namibian Dollar", symbol: "$" },
  { value: "NPR", label: "Nepalese Rupee", symbol: "₨" },
  { value: "ANG", label: "Netherlands Antillean Guilder", symbol: "ƒ" },
  { value: "TWD", label: "New Taiwan Dollar", symbol: "$" },
  { value: "NZD", label: "New Zealand Dollar", symbol: "$" },
  { value: "NIO", label: "Nicaraguan CÃ³rdoba", symbol: "C$" },
  { value: "NGN", label: "Nigerian Naira", symbol: "₦" },
  { value: "KPW", label: "North Korean Won", symbol: "₩" },
  { value: "NOK", label: "Norwegian Krone", symbol: "kr" },
  { value: "OMR", label: "Omani Rial", symbol: ".ع.ر" },
  { value: "PKR", label: "Pakistani Rupee", symbol: "₨" },
  { value: "PAB", label: "Panamanian Balboa", symbol: "B/." },
  { value: "PGK", label: "Papua New Guinean Kina", symbol: "K" },
  { value: "PYG", label: "Paraguayan Guarani", symbol: "₲" },
  { value: "PEN", label: "Peruvian Nuevo Sol", symbol: "S/." },
  { value: "PHP", label: "Philippine Peso", symbol: "₱" },
  { value: "PLN", label: "Polish Zloty", symbol: "zł" },
  { value: "QAR", label: "Qatari Rial", symbol: "ق.ر" },
  { value: "RON", label: "Romanian Leu", symbol: "lei" },
  { value: "RUB", label: "Russian Ruble", symbol: "₽" },
  { value: "RWF", label: "Rwandan Franc", symbol: "FRw" },
  { value: "SVC", label: "Salvadoran ColÃ³n", symbol: "₡" },
  { value: "WST", label: "Samoan Tala", symbol: "SAT" },
  { value: "SAR", label: "Saudi Riyal", symbol: "﷼" },
  { value: "RSD", label: "Serbian Dinar", symbol: "din" },
  { value: "SCR", label: "Seychellois Rupee", symbol: "SRe" },
  { value: "SLL", label: "Sierra Leonean Leone", symbol: "Le" },
  { value: "SGD", label: "Singapore Dollar", symbol: "$" },
  { value: "SKK", label: "Slovak Koruna", symbol: "Sk" },
  { value: "SBD", label: "Solomon Islands Dollar", symbol: "Si$" },
  { value: "SOS", label: "Somali Shilling", symbol: "Sh.so." },
  { value: "ZAR", label: "South African Rand", symbol: "R" },
  { value: "KRW", label: "South Korean Won", symbol: "₩" },
  { value: "XDR", label: "Special Drawing Rights", symbol: "SDR" },
  { value: "LKR", label: "Sri Lankan Rupee", symbol: "Rs" },
  { value: "SHP", label: "St. Helena Pound", symbol: "£" },
  { value: "SDG", label: "Sudanese Pound", symbol: ".س.ج" },
  { value: "SRD", label: "Surinamese Dollar", symbol: "$" },
  { value: "SZL", label: "Swazi Lilangeni", symbol: "E" },
  { value: "SEK", label: "Swedish Krona", symbol: "kr" },
  { value: "CHF", label: "Swiss Franc", symbol: "CHf" },
  { value: "SYP", label: "Syrian Pound", symbol: "LS" },
  { value: "STD", label: "São Tomé and Príncipe Dobra", symbol: "Db" },
  { value: "TJS", label: "Tajikistani Somoni", symbol: "SM" },
  { value: "TZS", label: "Tanzanian Shilling", symbol: "TSh" },
  { value: "THB", label: "Thai Baht", symbol: "฿" },
  { value: "TOP", label: "Tongan Pa'anga", symbol: "$" },
  { value: "TTD", label: "Trinidad & Tobago Dollar", symbol: "$" },
  { value: "TND", label: "Tunisian Dinar", symbol: "ت.د" },
  { value: "TRY", label: "Turkish Lira", symbol: "₺" },
  { value: "TMT", label: "Turkmenistani Manat", symbol: "T" },
  { value: "UGX", label: "Ugandan Shilling", symbol: "USh" },
  { value: "UAH", label: "Ukrainian Hryvnia", symbol: "₴" },
  { value: "AED", label: "United Arab Emirates Dirham", symbol: "إ.د" },
  { value: "UYU", label: "Uruguayan Peso", symbol: "$" },
  { value: "USD", label: "US Dollar", symbol: "$" },
  { value: "UZS", label: "Uzbekistan Som", symbol: "лв" },
  { value: "VUV", label: "Vanuatu Vatu", symbol: "VT" },
  { value: "VEF", label: "Venezuelan BolÃvar", symbol: "Bs" },
  { value: "VND", label: "Vietnamese Dong", symbol: "₫" },
  { value: "YER", label: "Yemeni Rial", symbol: "﷼" },
  { value: "ZMK", label: "Zambian Kwacha", symbol: "ZK" },
];

export const FALLBACK_EXCHANGE_RATES = {
  query: {
    apikey: "edb8e790-80e9-11ec-b216-05c50d27628b",
    timestamp: 1703289599,
    base_currency: "USD",
  },
  data: {
    ADA: 1.6024170841,
    AED: 3.6716206219,
    AFN: 69.9686671844,
    ALL: 94.1895545123,
    AMD: 406.2318315661,
    ANG: 1.7862901829,
    AOA: 830.2699353152,
    ARB: 0.702096805,
    ARS: 804.730099742,
    AUD: 1.4707002122,
    AVAX: 0.0220439218,
    AWG: 1.79,
    AZN: 1.7,
    BAM: 1.7786303183,
    BBD: 2,
    BDT: 109.6095519242,
    BGN: 1.7745002226,
    BHD: 0.376,
    BIF: 2846.9660441553,
    BMD: 1,
    BNB: 0.0035817421,
    BND: 1.3233601755,
    BOB: 6.9343410528,
    BRL: 4.8577506916,
    BSD: 1,
    BTC: 2.26783e-5,
    BTN: 83.4932108792,
    BUSD: 0.9984529518,
    BWP: 13.4350015316,
    BYN: 3.2949706712,
    BYR: 32949.700615891,
    BZD: 2,
    CAD: 1.3268002342,
    CDF: 2660.9731313172,
    CHF: 0.85565014,
    CLF: 0.023810004,
    CLP: 888.5634900544,
    CNY: 7.1316111071,
    COP: 3907.2915980826,
    CRC: 521.7033142911,
    CUC: 1,
    CUP: 24,
    CVE: 100.1346811176,
    CZK: 22.3022128738,
    DAI: 0.9997514085,
    DJF: 177.721,
    DKK: 6.767901212,
    DOP: 57.7115787293,
    DOT: 0.1255688991,
    DZD: 134.9974193092,
    EGP: 30.8992157374,
    ERN: 15,
    ETB: 56.3772585177,
    ETH: 0.0004294081,
    EUR: 0.907940099,
    FJD: 2.2002603888,
    FKP: 0.7875576963,
    GBP: 0.7875401208,
    GEL: 2.6841404154,
    GGP: 0.7875573564,
    GHS: 11.9814514386,
    GIP: 0.7875577691,
    GMD: 58.3361764363,
    GNF: 8548.0959544574,
    GTQ: 7.8113514328,
    GYD: 209.2102813923,
    HKD: 7.8101009762,
    HNL: 24.6561638552,
    HRK: 7.0199608603,
    HTG: 132.4780842975,
    HUF: 346.0080186834,
    IDR: 15462.838588496,
    ILS: 3.6089204699,
    IMP: 0.7875580277,
    INR: 83.0923605275,
    IQD: 1308.179630353,
    IRR: 42007.755484979,
    ISK: 136.7213441021,
    JEP: 0.7875576811,
    JMD: 156.1420261737,
    JOD: 0.71,
    JPY: 142.3963458551,
    KES: 155.9942148338,
    KGS: 89.3328162052,
    KHR: 4093.6075167908,
    KMF: 446.4884290865,
    KPW: 900.0223448451,
    KRW: 1295.4687687476,
    KWD: 0.307600036,
    KYD: 0.83333,
    KZT: 456.8028103116,
    LAK: 20590.49826958,
    LBP: 15009.295351172,
    LKR: 325.6226033622,
    LRD: 188.4192858529,
    LSL: 18.36767206,
    LTC: 0.0135597326,
    LTL: 3.1323312989,
    LVL: 0.6375704266,
    LYD: 4.7945206217,
    MAD: 9.9094315118,
    MATIC: 1.1404404592,
    MDL: 17.5154832462,
    MGA: 4537.5506139024,
    MKD: 55.7780171211,
    MMK: 2094.57581508,
    MNT: 3426.8341824991,
    MOP: 8.0074508699,
    MRO: 356.999828,
    MUR: 43.9919581256,
    MVR: 15.4515028316,
    MWK: 1683.2604186303,
    MXN: 16.9668020334,
    MYR: 4.6295307912,
    MZN: 63.6839567593,
    NAD: 18.3679927092,
    NGN: 905.9208452419,
    NIO: 36.6302728845,
    NOK: 10.2087818929,
    NPR: 133.120720467,
    NZD: 1.5879702201,
    OMR: 0.3841900509,
    OP: 0.2873664862,
    PAB: 0.9990001544,
    PEN: 3.7126104835,
    PGK: 3.7583304924,
    PHP: 55.395909842,
    PKR: 282.9041715173,
    PLN: 3.9321104265,
    PYG: 7392.903969411,
    QAR: 3.6399704535,
    RON: 4.513510606,
    RSD: 106.0081409313,
    RUB: 92.3041971565,
    RWF: 1255.3594391106,
    SAR: 3.7452106558,
    SBD: 8.4716964972,
    SCR: 14.3535116854,
    SDG: 601.5,
    SEK: 9.9982710006,
    SGD: 1.3221102606,
    SHP: 0.7875401326,
    SLL: 22625.339276379,
    SOL: 0.0102030144,
    SOS: 571.2620280226,
    SRD: 37.1644737228,
    STD: 22574.494285805,
    SVC: 8.75,
    SYP: 13001.919792159,
    SZL: 18.4192528309,
    THB: 34.6080464251,
    TJS: 10.937092156,
    TMT: 3.5,
    TND: 3.0809605596,
    TOP: 2.3321504503,
    TRY: 29.2383730799,
    TTD: 6.7825107837,
    TWD: 31.11468457,
    TZS: 2512.7978104734,
    UAH: 37.4420059948,
    UGX: 3803.9113434268,
    USD: 1,
    USDC: 0.9994442748,
    USDT: 0.9988363692,
    UYU: 39.2717662694,
    UZS: 12374.114060009,
    VEF: 3575554.5205252,
    VND: 24332.327976554,
    VUV: 117.3662515791,
    WST: 2.6892570563,
    XAF: 595.5001143332,
    XAG: 0.0413453183,
    XAU: 0.0004870526,
    XCD: 2.7,
    XDR: 0.7457401401,
    XOF: 595.5000956122,
    XPD: 0.0008285418,
    XPF: 108.2597142834,
    XPT: 0.0010351006,
    XRP: 1.5928573902,
    YER: 249.7606280758,
    ZAR: 18.5217932017,
    ZMK: 9001.2,
    ZMW: 25.4639441001,
    ZWL: 5907.0955123577,
  },
};

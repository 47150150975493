import React, { Fragment, useState } from "react";
import classNames from "classnames";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  CogIcon,
  HomeIcon,
  MenuAlt2Icon,
  PlusSmIcon,
  XIcon,
  BriefcaseIcon,
  DocumentTextIcon,
  PresentationChartLineIcon,
  SwitchVerticalIcon,
} from "@heroicons/react/outline";
import { SearchIcon } from "@heroicons/react/solid";

import { useAuthProvider } from "components/AuthProvider";
import AppShell from "components/AppShell";
import Link from "components/Link";

import Breadcrumbs from "./Breadcrumbs";
import NetWorthCard from "./NetWorthCard";
import UpdateNotificationsCard from "./UpdateNotificationsCard";
import RecentActivityCard from "./RecentActivityCard";

export default function Layout() {
  const location = useLocation();
  const auth = useAuthProvider();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  if (!auth.ready) {
    return <AppShell />;
  }

  if (!auth.currentUser) {
    return <Navigate to="/signin" state={{ from: location }} />;
  }

  // if (auth.settings && !auth.settings.onboardingCompleted) {
  //   return <Navigate to="/setup" />;
  // }

  return (
    <>
      <div className="flex h-full">
        <div className="hidden overflow-y-auto bg-indigo-900 w-28 md:block">
          <div className="flex flex-col items-center w-full py-6">
            <div className="flex items-center flex-shrink-0">
              <img
                className="w-auto h-8"
                src="https://tailwindui.com/img/logos/workflow-mark.svg?color=white"
                alt="Workflow"
              />
            </div>
            <div className="flex-1 w-full px-2 mt-6 space-y-3">
              {SIDEBAR_NAVIGATION.map((item) => {
                return (
                  <Link
                    key={item.name}
                    to={item.link}
                    activeClassName={"bg-indigo-100 text-black"}
                    inactiveClassName={
                      "text-indigo-200 hover:bg-indigo-200 hover:text-black"
                    }
                    className={
                      "group w-full p-3 rounded-lg flex flex-col items-center text-xs font-medium"
                    }
                  >
                    {({ match }) => {
                      return (
                        <>
                          <item.icon
                            className={classNames(
                              match
                                ? "text-black"
                                : "text-indigo-200 group-hover:text-black",
                              "h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          <span className="mt-2">{item.name}</span>
                        </>
                      );
                    }}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>

        <Transition.Root show={mobileMenuOpen} as={Fragment}>
          <Dialog as="div" className="md:hidden" onClose={setMobileMenuOpen}>
            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <div className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-indigo-700">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute right-0 p-1 top-1 -mr-14">
                      <button
                        type="button"
                        className="flex items-center justify-center w-12 h-12 rounded-full focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        <XIcon
                          className="w-6 h-6 text-white"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Close sidebar</span>
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex items-center flex-shrink-0 px-4">
                    <img
                      className="w-auto h-8"
                      src="https://tailwindui.com/img/logos/workflow-mark.svg?color=white"
                      alt="Workflow"
                    />
                  </div>
                  <div className="flex-1 h-0 px-2 mt-5 overflow-y-auto">
                    <nav className="flex flex-col h-full">
                      <div className="space-y-1">
                        {SIDEBAR_NAVIGATION.map((item) => (
                          <Link
                            key={item.name}
                            to={item.link}
                            activeClassName={"bg-indigo-800 text-white"}
                            inactiveClassName={
                              "text-indigo-100 hover:bg-indigo-800 hover:text-white"
                            }
                            className={
                              "group py-2 px-3 rounded-md flex items-center text-sm font-medium"
                            }
                            onClick={() => {
                              setTimeout(() => setMobileMenuOpen(false));
                            }}
                          >
                            <item.icon
                              className={classNames(
                                item.current
                                  ? "text-white"
                                  : "text-indigo-300 group-hover:text-white",
                                "mr-3 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            <span>{item.name}</span>
                          </Link>
                        ))}
                      </div>
                    </nav>
                  </div>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="flex flex-col flex-1 overflow-hidden">
          <header className="w-full">
            <div className="relative z-10 flex flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm">
              <button
                type="button"
                className="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <MenuAlt2Icon className="w-6 h-6" aria-hidden="true" />
              </button>
              <div className="flex justify-between flex-1 px-4 sm:px-6">
                <div className="flex flex-1">
                  <form className="flex w-full md:ml-0" action="#" method="GET">
                    <label htmlFor="search-field" className="sr-only">
                      Search all files
                    </label>
                    <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                      <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                        <SearchIcon
                          className="flex-shrink-0 w-5 h-5"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        name="search-field"
                        id="search-field"
                        className="w-full h-full py-2 pl-8 pr-3 text-base text-gray-900 placeholder-gray-500 border-transparent focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400"
                        placeholder="Search"
                        type="search"
                      />
                    </div>
                  </form>
                </div>
                <div className="flex items-center ml-2 space-x-4 sm:ml-6 sm:space-x-6">
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative flex-shrink-0">
                    <div>
                      <Menu.Button className="flex text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="w-8 h-8 rounded-full"
                          src="https://pbs.twimg.com/profile_images/1086311306343403520/3NzOyOoM_400x400.jpg"
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {USER_NAVIGATION.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                href={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                                onClick={() => {
                                  if (item.name === "Sign out") {
                                    auth.signOut();
                                  }
                                }}
                              >
                                {item.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>

                  {false && (
                    <button
                      type="button"
                      className="flex items-center justify-center p-1 text-white bg-indigo-600 rounded-full hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <PlusSmIcon className="w-6 h-6" aria-hidden="true" />
                      <span className="sr-only">Add file</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </header>

          {/* Main content */}
          <div className="flex items-stretch flex-1 overflow-hidden">
            <main className="flex-1 overflow-x-hidden overflow-y-auto">
              {/* Primary column */}
              <section
                aria-labelledby="primary-heading"
                className="flex flex-col flex-1 min-w-0 min-h-full p-4 lg:order-last sm:p-6"
              >
                <Breadcrumbs />
                <Outlet />
                <div className="h-16 md:hidden"></div>
              </section>
            </main>

            {/* Secondary column (hidden on smaller screens) */}
            <aside className="flex-col items-stretch hidden space-y-6 overflow-y-auto bg-white border-l border-gray-200 w-96 lg:block">
              <NetWorthCard />
              <UpdateNotificationsCard />
              {/*<RecentActivityCard />*/}
            </aside>
          </div>
        </div>
      </div>
    </>
  );
}

const SIDEBAR_NAVIGATION = [
  { name: "Home", link: "/", icon: HomeIcon },
  {
    name: "Investments",
    link: "/investments",
    icon: BriefcaseIcon,
    current: false,
  },
  { name: "Cashflow", link: "/cashflow", icon: SwitchVerticalIcon },
  { name: "Accounts", link: "/accounts", icon: DocumentTextIcon },
  { name: "Tools", link: "/tools", icon: CogIcon },
  { name: "Reports", link: "/reports", icon: PresentationChartLineIcon },
  // { name: "Settings", link: "/settings", icon: CogIcon },
];

const USER_NAVIGATION = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];

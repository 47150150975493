import { useState } from "react";
import { subDays, subMonths } from "date-fns";

import useAccounts from "hooks/data/useAccounts";
import useInvestments from "hooks/data/useInvestments";
import * as Accountant from "services/accountant";

const TODAY = new Date();

export default function useNetWorthSeries() {
  const {
    investments,
    loading: investmentsLoading,
    error: investmentsError,
  } = useInvestments();
  const {
    accounts,
    loading: accountsLoading,
    error: accountsError,
  } = useAccounts();

  const [range, setRange] = useState(TIME_RANGE_OPTIONS[0]);
  return {
    range,
    setRange,
    data:
      !investmentsLoading && !accountsLoading
        ? Accountant.netWorthSeries({
            accounts,
            investments,
            startAt: range.start,
            endAt: range.end,
          })
        : [],
    loading: investmentsLoading || accountsLoading,
  };
}

export const TIME_RANGE_OPTIONS = [
  {
    label: "Last 30 days",
    value: "30d",
    start: subDays(TODAY, 30),
    end: TODAY,
  },
  {
    label: "Last 3 months",
    value: "3m",
    start: subMonths(TODAY, 3),
    end: TODAY,
  },
  {
    label: "Last 6 months",
    value: "6m",
    start: subMonths(TODAY, 6),
    end: TODAY,
  },
  {
    label: "Last 12 months",
    value: "12m",
    start: subMonths(TODAY, 12),
    end: TODAY,
  },
];

import useInvestments from "hooks/data/useInvestments";
import useAccounts from "hooks/data/useAccounts";

export default function useUpdateNotifications() {
  const { investments, loading: investmentsLoading } = useInvestments();
  const { accounts, loading: accountsLoading } = useAccounts();

  const investmentsReady = investments && !investmentsLoading;
  const accountsReady = accounts && !accountsLoading;
  return {
    visible:
      investmentsReady &&
      accountsReady &&
      (investments.length > 0 || accounts.length > 0),
    investmentToBeUpdated: investmentsReady
      ? getOutdatedInvestment(investments)
      : null,
    accountToBeUpdated: accountsReady ? getOutdatedAccount(accounts) : null,
  };
}

function getOutdatedInvestment(investments) {
  return investments
    .filter((investment) => !investment.closed)
    .sort((i1, i2) => i2.invested.value - i1.invested.value)
    .filter((investment) => {
      const [latestEntry] = investment.entries;
      const now = new Date().getTime();
      return now - latestEntry.timestamp > 22 * 60 * 60 * 1000;
    })[0];
}

function getOutdatedAccount(accounts) {
  return accounts
    .filter((account) => !account.closed)
    .sort((i1, i2) => i2.currentValue.value - i1.currentValue.value)
    .filter((account) => {
      const [latestEntry] = account.entries;
      const now = new Date().getTime();
      return now - latestEntry.timestamp > 22 * 60 * 60 * 1000;
    })[0];
}

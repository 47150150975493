import React, { useCallback, useState } from "react";
import {
  TrendingUpIcon,
  CalendarIcon,
  PencilIcon,
  SaveAsIcon,
  LockClosedIcon,
  LockOpenIcon,
} from "@heroicons/react/outline";
import classNames from "classnames";
import toast from "react-hot-toast";

import Button from "components/Button";
import Modal from "components/Modal";
import Notification from "components/Notification";
import { formatTimestamp } from "services/firebase";
import * as Accountant from "services/accountant";
import * as InvestmentsApis from "apis/investments";

import EditInvestmentModal from "./EditInvestmentModal";
import RecordUpdateModal from "./RecordUpdateModal";

export default function InvestmentHeader({ investment }) {
  const [showRecordUpdateModal, setShowRecordUpdateModal] = useState(false);
  const [showEditInvestmentModal, setShowEditInvestmentModal] = useState(false);
  const xirr = parseFloat(Accountant.investmentXirr({ investment })).toFixed(2);

  return (
    <header className="pb-6 border-b border-gray-200">
      <div className="mx-auto xl:flex xl:items-center xl:justify-between">
        <div className="flex-1 min-w-0">
          <div className="flex items-center">
            <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              {investment.name}
            </h1>
            {investment.color ? (
              <span
                className="w-5 h-5 mt-2 ml-3 rounded-full"
                style={{ backgroundColor: investment.color }}
              />
            ) : null}
          </div>
          <div className="flex flex-col items-start mt-2 sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
            {investment.createdAt ? (
              <div className="flex items-center mt-2 text-sm text-gray-500">
                <CalendarIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                Added on{" "}
                <span className="ml-1 font-medium">
                  {formatTimestamp(investment.createdAt, "dd MMMM, yyyy")}
                </span>
              </div>
            ) : null}
            <div className="flex items-center mt-2 text-sm text-gray-500">
              <TrendingUpIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              Target XIRR:{" "}
              <span className="ml-1 font-medium">{investment.targetXirr}%</span>
            </div>
            <div
              className={classNames(
                "x" === "increase"
                  ? "bg-green-100 text-green-800"
                  : "bg-red-100 text-red-800",
                "flex items-center mt-2 px-2.5 py-0.5 rounded-full text-sm font-medium"
              )}
            >
              XIRR: {xirr}
            </div>
          </div>
        </div>
        <div className="flex mt-5 xl:mt-0 xl:ml-4">
          <span className="block">
            <Button
              variant="secondary"
              onClick={() => setShowEditInvestmentModal(true)}
            >
              <PencilIcon
                className="w-3.5 h-3.5 mr-2 -ml-1 text-gray-400 sm:w-5 sm:h-5"
                aria-hidden="true"
              />
              Edit
            </Button>
          </span>
          {!investment.closed && (
            <span className="block ml-3">
              <Button onClick={() => setShowRecordUpdateModal(true)}>
                <SaveAsIcon
                  className="w-3.5 h-3.5 sm:w-5 sm:h-5 mr-2 -ml-1 text-white"
                  aria-hidden="true"
                />
                Record Update
              </Button>
            </span>
          )}
          <span className="block ml-3">
            <InvestmentCloseToggle investment={investment} />
          </span>
        </div>
      </div>
      <Modal
        open={showRecordUpdateModal}
        onClose={() => setShowRecordUpdateModal(false)}
        content={
          <RecordUpdateModal
            investment={investment}
            close={() => setShowRecordUpdateModal(false)}
          />
        }
      />
      <Modal
        open={showEditInvestmentModal}
        onClose={() => setShowEditInvestmentModal(false)}
        content={
          <EditInvestmentModal
            investment={investment}
            close={() => setShowEditInvestmentModal(false)}
          />
        }
      />
    </header>
  );
}

function InvestmentCloseToggle({ investment }) {
  const onClick = useCallback(async () => {
    const isClosed = investment.closed;
    const confirmAction = window.confirm(
      `Are you sure you want to ${
        isClosed ? "re-open" : "close"
      } this investment.`
    );
    if (confirmAction) {
      try {
        await InvestmentsApis.update(investment.id, {
          closed: Boolean(!investment.closed),
        });
        toast.custom((t) => (
          <Notification
            toast={t}
            variant="success"
            content={
              <p className="text-sm font-medium text-gray-900">
                Investment has been updated successfully.
              </p>
            }
          />
        ));
      } catch (err) {
        console.log(err, "error in updating the invesment");
        toast.custom((t) => (
          <Notification
            toast={t}
            variant="error"
            content={
              <p className="text-sm font-medium text-gray-900">
                There was an error in updating the investment.
              </p>
            }
          />
        ));
      }
    }
  }, [investment.id, investment.closed]);

  return (
    <Button
      variant={investment.closed ? "secondaryGreen" : "secondaryRed"}
      onClick={onClick}
    >
      {investment.closed ? (
        <LockOpenIcon
          aria-hidden="true"
          className="w-3.5 h-3.5 sm:w-5 sm:h-5 mr-2 -ml-1 text-green"
        />
      ) : (
        <LockClosedIcon
          className="w-3.5 h-3.5 sm:w-5 sm:h-5 mr-2 -ml-1 text-red"
          aria-hidden="true"
        />
      )}
      {investment.closed ? "Reopen" : "Close"}
    </Button>
  );
}

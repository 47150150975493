import { Outlet, useOutlet } from "react-router-dom";

import AccountsCard from "containers/Dashboard/AccountsCard";

export default function AccountsContainer() {
  const match = useOutlet();

  if (match.props.children) {
    return <Outlet />;
  } else {
    return (
      <div className="flex flex-col space-y-9">
        <AccountsCard displayClosedAccounts={true} />
      </div>
    );
  }
}

import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { format as formatDate, parse as parseDate } from "date-fns";

import { useAuthProvider } from "components/AuthProvider";
import * as ExpensesApi from "apis/expenses";

export default function useCreateExpense({ expenses, onCreateSuccess }) {
  const authProvider = useAuthProvider();
  const form = useForm({
    defaultValues: {
      reason: "",
      amount: {
        currency: authProvider.settings.currency,
        value: "",
      },
      date: formatDate(new Date(), "dd/MM/yyyy"),
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      try {
        const expense = await ExpensesApi.create({
          userId: authProvider.currentUser.uid,
          reason: data.reason,
          amount: data.amount,
          timestamp: parseDate(data.date, "dd/MM/yyyyy", new Date()).getTime(),
        });

        if (typeof onCreateSuccess === "function") {
          onCreateSuccess(expense);
        }
      } catch (err) {
        console.log(err, "erorr!");
      }
    },
    [authProvider, onCreateSuccess]
  );

  return {
    form,
    handleSubmit: form.handleSubmit(onSubmit),
  };
}

import Loaders from "components/Loaders";
import Currency from "components/Currency";
import useExpensesSummary, {
  TIME_RANGE_OPTIONS,
} from "./hooks/useExpensesSummary";

export default function ExpensesSummaryCard() {
  const expenseSummary = useExpensesSummary();

  return (
    <div className="flex-col overflow-hidden bg-white border rounded-sm shadow-sm border-gray-50">
      <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
        <div className="flex flex-wrap items-center justify-between -mt-2 -ml-4 sm:flex-nowrap">
          <div className="flex flex-col items-stretch mt-2 ml-4 space-y-1">
            <h3 className="text-xl font-medium leading-6 text-gray-900">
              Expenses Summary
            </h3>
          </div>
          <div className="flex-shrink-0 mt-2 ml-4">
            <select
              id="monthlySeriesCashflowGroupBy"
              name="monthlySeriesCashflowGroupBy"
              className="mb-2 cursor-pointer block py-0.5 ml-auto mx-3 pr-8 text-sm border-none bg-transparent rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              value={expenseSummary.timeRange.value}
              onChange={(e) => expenseSummary.setTimeRange(e.target.value)}
            >
              {TIME_RANGE_OPTIONS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {expenseSummary.loading ? (
        <div className="flex justify-center py-12">
          <Loaders.Spinner className="w-6 h-6 text-indigo-600" />
        </div>
      ) : null}
      <div className="flex flex-col items-start md:flex-row">
        <div className="flex-col self-stretch px-6 py-2 lg:w-1/2">
          {expenseSummary.expensesByTags ? (
            <dl className="text-sm divide-y divide-gray-100">
              {Object.keys(expenseSummary.expensesByTags)
                .sort((tag1, tag2) => {
                  return (
                    expenseSummary.expensesByTags[tag2].total.value -
                    expenseSummary.expensesByTags[tag1].total.value
                  );
                })
                .map((tag) => {
                  const value = expenseSummary.expensesByTags[tag];
                  return (
                    <div
                      className="flex items-center justify-between py-3"
                      key={tag}
                    >
                      <dt className="text-gray-600">{tag}</dt>
                      <dd className="font-medium text-red-700">
                        <Currency value={value.total} />
                      </dd>
                    </div>
                  );
                })}
            </dl>
          ) : null}
        </div>
      </div>
    </div>
  );
}

import { addDoc, collection } from "firebase/firestore";
import { firebaseStore } from "services/firebase";

export async function create(data) {
  const collectionRef = collection(firebaseStore, "incomes");
  return await addDoc(collectionRef, data);
}

export async function update() {}

export async function remove() {}

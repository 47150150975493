import React from "react";

import ExpensesCard from "./ExpensesCard";
import IncomesCard from "./IncomesCard";

export default function Cashflow() {
  return (
    <div className="grid grid-cols-1 xl:grid-cols-3 gap-x-6 gap-y-6">
      <div className="col-span-1 xl:col-span-2">
        <ExpensesCard />
      </div>
      <div className="col-span-1">
        <IncomesCard />
      </div>
    </div>
  );
}

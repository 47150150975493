import { useEffect, useState } from "react";
import { subMonths, subDays } from "date-fns";

import useAccounts from "hooks/data/useAccounts";
import useExpenses from "hooks/data/useExpenses";
import useIncomes from "hooks/data/useIncomes";
import useInvestments from "hooks/data/useInvestments";

import * as Accountant from "services/accountant";

export default function useInvestmentPlanner() {
  const { accounts, loading: accountsLoading } = useAccounts();
  const { expenses, loading: expensesLoading } = useExpenses();
  const { incomes, loading: incomesLoading } = useIncomes();
  const { investments, loading: investmentsLoading } = useInvestments();
  const [lookbackRange, setLookbackRange] = useState(LOOKBACK_RANGE_OPTIONS[0]);

  const [predictionYears, setPredictionYears] = useState(10);
  const [investmentContribution, setInvestmentContribution] = useState(70);
  const [investmentReturns, setInvestmentReturns] = useState(12);

  const [savingsContribution, setSavingsContribution] = useState(30);
  const [savingsReturns, setSavingsReturns] = useState(2);

  const ready =
    !expensesLoading &&
    !incomesLoading &&
    !investmentsLoading &&
    !accountsLoading;

  const averageDailyIncome = ready
    ? Accountant.averageDailyIncome({
        incomes,
        timeRange: lookbackRange,
      })
    : null;

  const averageDailyExpense = ready
    ? Accountant.averageDailyExpense({
        expenses,
        timeRange: lookbackRange,
      })
    : null;

  let predictedInvestmentsValue;
  let predictedSavingsValue;
  let predictionCurrency;
  if (averageDailyIncome && averageDailyExpense) {
    const dailyCashflow = averageDailyIncome.value - averageDailyExpense.value;
    const yearlyCashflow = dailyCashflow * 365;
    const investmentValueToday = Accountant.totalCurrentValue(investments);
    predictionCurrency = averageDailyIncome.currency;

    predictedInvestmentsValue =
      Accountant.compoundInterest({
        principal: investmentValueToday,
        rate: investmentReturns / 100,
        years: predictionYears,
      }) +
      Accountant.futureValue({
        yearlyContribution: (yearlyCashflow * investmentContribution) / 100,
        rate: investmentReturns / 100,
        years: predictionYears,
      });

    predictedSavingsValue =
      Accountant.compoundInterest({
        principal: Accountant.totalAccountsValue(accounts),
        rate: savingsReturns / 100,
        years: predictionYears,
      }) +
      Accountant.futureValue({
        yearlyContribution: (yearlyCashflow * savingsContribution) / 100,
        rate: savingsReturns / 100,
        years: predictionYears,
      });
  }

  return {
    ready,

    lookbackRange,
    setLookbackRange: (value) =>
      setLookbackRange(
        LOOKBACK_RANGE_OPTIONS.filter((o) => o.value === value)[0]
      ),

    predictionYears,
    setPredictionYears,

    investmentContribution,
    setInvestmentContribution: (value) => {
      setInvestmentContribution(value);
      setSavingsContribution(100 - value);
    },
    investmentReturns,
    setInvestmentReturns,

    savingsContribution,
    setSavingsContribution: (value) => {
      setSavingsContribution(value);
      setInvestmentContribution(100 - value);
    },
    savingsReturns,
    setSavingsReturns,

    averageDailyIncome,
    averageDailyExpense,

    averageDailyInvestment: ready
      ? Accountant.averageDailyInvestment({
          investments,
          timeRange: lookbackRange,
        })
      : null,
    averageDailySaving: ready
      ? Accountant.averageDailySaving({
          accounts,
          timeRange: lookbackRange,
        })
      : null,

    predictedInvestments: {
      value: predictedInvestmentsValue,
      currency: predictionCurrency,
    },
    predictedSavings: {
      value: predictedSavingsValue,
      currency: predictionCurrency,
    },
  };
}

const TODAY = new Date();
export const LOOKBACK_RANGE_OPTIONS = [
  {
    label: "Last 3 months",
    value: "3m",
    start: subMonths(TODAY, 3),
    end: TODAY,
  },
  {
    label: "Last 6 months",
    value: "6m",
    start: subMonths(TODAY, 6),
    end: TODAY,
  },
  {
    label: "Last 12 months",
    value: "12m",
    start: subMonths(TODAY, 12),
    end: TODAY,
  },
];

import { useCallback } from "react";
import { useForm } from "react-hook-form";

// import { useAuthProvider } from "components/AuthProvider";
import * as InvestmentsApis from "apis/investments";
import { INVESTMENTS_COLOR_PALLETTE } from "constants/colors";

export default function useRecordUpdate({ investment, onCreateSuccess }) {
  const form = useForm({
    defaultValues: {
      name: investment.name,
      color: investment.color || INVESTMENTS_COLOR_PALLETTE[0],
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      try {
        const updatedInvestment = await InvestmentsApis.update(investment.id, {
          name: data.name,
          color: data.color,
        });

        if (typeof onCreateSuccess === "function") {
          onCreateSuccess(updatedInvestment);
        }
      } catch (err) {
        console.log(err, "erorr!");
      }
    },
    [onCreateSuccess, investment]
  );

  return {
    form,
    handleSubmit: form.handleSubmit(onSubmit),
  };
}

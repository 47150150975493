import toast from "react-hot-toast";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import Notification from "components/Notification";
import { useAuthProvider } from "components/AuthProvider";
import { firebaseErrorDescription } from "services/firebase";

export default function useSignup() {
  const navigate = useNavigate();
  const authProvider = useAuthProvider();
  const form = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      try {
        await authProvider.signUp(data);
        navigate("/");
      } catch (err) {
        toast.custom((t) => (
          <Notification
            toast={t}
            variant="error"
            content={
              <p className="text-sm font-medium">
                {firebaseErrorDescription(err) ||
                  "Sorry, there was an unexpected error in creating your account."}
              </p>
            }
          />
        ));
      }
    },
    [authProvider, navigate]
  );

  return {
    form,
    handleSubmit: form.handleSubmit(onSubmit),
  };
}

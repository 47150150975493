import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { format as formatDate } from "date-fns";

import { useAuthProvider } from "components/AuthProvider";
import * as GoalsApis from "apis/goals";

export default function useCreateGoal({ onCreateSuccess }) {
  const authProvider = useAuthProvider();
  const form = useForm({
    defaultValues: {
      title: "",
      targetDate: formatDate(new Date(), "dd/MM/yyyy"),
      targetAmount: {
        currency: authProvider.settings.currency,
        value: "",
      },
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      try {
        const goal = await GoalsApis.create({
          userId: authProvider.currentUser.uid,
          ...data,
        });

        if (typeof onCreateSuccess === "function") {
          onCreateSuccess(goal);
        }
      } catch (err) {
        console.log(err, "erorr in goal creation!");
      }
    },
    [authProvider, onCreateSuccess]
  );

  return {
    form,
    handleSubmit: form.handleSubmit(onSubmit),
  };
}

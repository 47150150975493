import React, { useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut as firebaseAuthSignOut,
} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";

import { firebaseAuth } from "services/firebase";
import useUserSettings from "./hooks/useUserSettings";
import useExchangeRates from "./hooks/useExchangeRates";

const AuthContext = React.createContext(null);

export default function AuthProvider({ children }) {
  const [ready, setReady] = useState(false);
  const [user, loadingUser, error] = useAuthState(firebaseAuth);
  const {
    settings,
    update: updateSettings,
    loading: loadingUserSettings,
  } = useUserSettings({
    user,
  });
  const { data: exchangeRates, loading: loadingExchangeRates } =
    useExchangeRates();

  useEffect(() => {
    if (!ready && !loadingUser) {
      setReady(user ? !loadingExchangeRates && !loadingUserSettings : true);
    }
  }, [loadingUser, ready, loadingExchangeRates, loadingUserSettings, user]);

  return (
    <AuthContext.Provider
      value={{
        settings,
        updateSettings,
        exchangeRates,
        ready,
        error,
        currentUser: user,
        signUp: ({ email, password }) =>
          createUserWithEmailAndPassword(firebaseAuth, email, password),
        signIn: ({ email, password }) =>
          signInWithEmailAndPassword(firebaseAuth, email, password),
        signOut: () => firebaseAuthSignOut(firebaseAuth),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthProvider() {
  return useContext(AuthContext);
}

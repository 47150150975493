import React from "react";

import Loaders from "components/Loaders";
import Currency from "components/Currency";
import useInvestmentPlanner, {
  LOOKBACK_RANGE_OPTIONS,
} from "./hooks/useInvestmentPlanner";

export default function InvestmentPlanner() {
  const investmentPlanner = useInvestmentPlanner();

  return (
    <div className="flex-col overflow-hidden bg-white border rounded-sm shadow-sm border-gray-50">
      <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
        <div className="flex flex-wrap items-center justify-between -mt-2 -ml-4 sm:flex-nowrap">
          <div className="flex flex-col items-stretch mt-2 ml-4 space-y-1">
            <h3 className="text-xl font-medium leading-6 text-gray-900">
              Investment Planner
            </h3>
          </div>
          <div className="flex flex-shrink-0 mt-2 ml-4">
            <span className="mt-1 mr-4 text-xs">Set Lookback Range</span>
            <select
              id="monthlySeriesCashflowGroupBy"
              name="monthlySeriesCashflowGroupBy"
              className="mb-2 cursor-pointer block py-0.5 ml-auto mx-3 pr-8 text-sm border-none bg-transparent rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              value={investmentPlanner.lookbackRange.value}
              onChange={(e) =>
                investmentPlanner.setLookbackRange(e.target.value)
              }
            >
              {LOOKBACK_RANGE_OPTIONS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {!investmentPlanner.ready ? (
        <div className="flex justify-center py-12">
          <Loaders.Spinner className="w-6 h-6 text-indigo-600" />
        </div>
      ) : null}
      {investmentPlanner.ready ? (
        <div className="flex flex-col items-center">
          <div className="flex-row">
            <span className="mr-2">Average Daily Income</span>
            <Currency value={investmentPlanner.averageDailyIncome} />
          </div>
          <div className="flex-row">
            <span className="mr-2">Average Daily Expense</span>
            <Currency value={investmentPlanner.averageDailyExpense} />
          </div>
          <div className="flex-row">
            <span className="mr-2">Average Daily Investment</span>
            <Currency value={investmentPlanner.averageDailyInvestment} />
          </div>
          <div className="flex-row mb-4">
            <span className="mr-2">Average Daily Saving</span>
            <Currency value={investmentPlanner.averageDailySaving} />
          </div>
          <div className="flex-row">
            <div>
              <label
                htmlFor="name"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                Number of years
              </label>
              <input
                type="number"
                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={investmentPlanner.predictionYears}
                onChange={(e) =>
                  investmentPlanner.setPredictionYears(e.target.value)
                }
              />
            </div>
          </div>
          <div className="flex flex-row w-full py-4 space-x-4 divide-x divide-gray-100">
            <div className="flex flex-col items-center flex-1 px-4 space-y-2">
              <span>Investments</span>
              <PredictionInput
                label={"Contribution"}
                value={investmentPlanner.investmentContribution}
                setValue={investmentPlanner.setInvestmentContribution}
              />
              <PredictionInput
                label={"Expected Return"}
                value={investmentPlanner.investmentReturns}
                setValue={investmentPlanner.setInvestmentReturns}
              />
            </div>
            <div className="flex flex-col items-center flex-1 px-4 space-y-2">
              <span>Savings</span>
              <PredictionInput
                label={"Contribution"}
                value={investmentPlanner.savingsContribution}
                setValue={investmentPlanner.setSavingsContribution}
              />
              <PredictionInput
                label={"Expected Return"}
                value={investmentPlanner.savingsReturns}
                setValue={investmentPlanner.setSavingsReturns}
              />
            </div>
          </div>
          <div className="flex flex-col items-center mt-4">
            Predictions
            <div className="flex-row">
              <span className="mr-2">Investments</span>
              <Currency value={investmentPlanner.predictedInvestments} />
            </div>
            <div className="flex-row">
              <span className="mr-2">Savings</span>
              <Currency value={investmentPlanner.predictedSavings} />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

function PredictionInput({ label, value, setValue }) {
  return (
    <div>
      <label
        htmlFor="name"
        className="block mb-1 text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="flex mt-1 rounded-md shadow-sm">
        <input
          type="number"
          min="0"
          max="100"
          name="investment-contribution"
          id="investment-contribution"
          className="flex-1 block w-full px-3 py-2 border-gray-300 rounded-none min-w-8 rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          placeholder="Enter percentage of cashflow to invest"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <span className="inline-flex items-center px-3 text-gray-500 border border-l-0 border-gray-300 rounded-r-md bg-gray-50 sm:text-sm">
          %
        </span>
      </div>
    </div>
  );
}

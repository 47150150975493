import { _find } from "services/lodash";

import * as Accountant from "services/accountant";

export default function useInvestmentStats({ investment, compareRangeInDays }) {
  return [
    getInvestedAmountStat({ investment, compareRangeInDays }),
    getCurrentValueStat({ investment, compareRangeInDays }),
    getProfitAndLossStat({ investment, compareRangeInDays }),
  ];
}

function getInvestedAmountStat({ investment, compareRangeInDays }) {
  const stat = investment.invested;
  const previousStat = getPreviousStat({
    entries: investment.entries,
    compareRangeInDays,
  });

  // TODO: Handle multiple currencies
  const change =
    ((stat.value - previousStat.invested.value) * 100) /
    previousStat.invested.value;
  const changeType = change > 0 ? "increase" : "decrease";

  return {
    name: "Invested Amount",
    stat,
    statType: "currency",
    previousStat: previousStat.invested,
    change: `${change.toFixed(2)}%`,
    changeType,
  };
}

function getCurrentValueStat({ investment, compareRangeInDays }) {
  const stat = investment.currentValue;
  const previousStat = getPreviousStat({
    entries: investment.entries,
    compareRangeInDays,
  });

  const change =
    ((stat.value - previousStat.currentValue.value) * 100) /
    previousStat.currentValue.value;
  const changeType = change > 0 ? "increase" : "decrease";

  return {
    name: "Current Value",
    stat,
    statType: "currency",
    previousStat: previousStat.currentValue,
    change: `${change.toFixed(2)}%`,
    changeType,
  };
}

function getProfitAndLossStat({ investment, compareRangeInDays }) {
  const stat = Accountant.portfolioProfitAndLoss({
    invested: investment.invested,
    current: investment.currentValue,
  }).absolute;
  const previousStatEntry = getPreviousStat({
    entries: investment.entries,
    compareRangeInDays,
  });

  const previousStat = Accountant.portfolioProfitAndLoss({
    invested: previousStatEntry.invested,
    current: previousStatEntry.currentValue,
  }).absolute;

  const change =
    ((stat.value - previousStat.value) * 100) / Math.abs(previousStat.value);
  const changeType = change > 0 ? "increase" : "decrease";

  return {
    name: "P&L",
    stat,
    statType: "currency",
    previousStat,
    change: `${change.toFixed(2)}%`,
    changeType,
  };
}

function getPreviousStat({ entries, compareRangeInDays }) {
  const now = new Date().getTime();

  return (
    _find(
      entries.sort((e1, e2) => e2.timestamp - e1.timestamp),
      (entry) =>
        now - entry.timestamp >= compareRangeInDays * 24 * 60 * 60 * 1000
    ) || entries[entries.length - 1]
  );
}

import NetWorthSeriesCard from "./NetWorthSeriesCard";
import MonthlyCashflowCard from "./MonthlyCashflowCard";
import ExpensesSummaryCard from "./ExpensesSummaryCard";

export default function ReportsContainer() {
  return (
    <div className="flex flex-col space-y-9">
      <NetWorthSeriesCard />
      <MonthlyCashflowCard />
      <ExpensesSummaryCard />
    </div>
  );
}

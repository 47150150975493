import { useCallback } from "react";
import { useForm } from "react-hook-form";

import { useAuthProvider } from "components/AuthProvider";
import * as AccountsApis from "apis/accounts";

export default function useCreateAccount({ onCreateSuccess }) {
  const authProvider = useAuthProvider();
  const form = useForm({
    defaultValues: {
      name: "",
      interestRate: "",
      currentValue: {
        currency: authProvider.settings.currency,
        value: "",
      },
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      try {
        const account = await AccountsApis.create({
          userId: authProvider.currentUser.uid,
          name: data.name,
          interestRate: data.interestRate,
          currentValue: data.currentValue,
        });

        if (typeof onCreateSuccess === "function") {
          onCreateSuccess(account);
        }
      } catch (err) {
        console.log(err, "erorr in account creation!");
      }
    },
    [authProvider, onCreateSuccess]
  );

  return {
    form,
    handleSubmit: form.handleSubmit(onSubmit),
  };
}

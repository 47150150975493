import classNames from "classnames";

import { INVESTMENTS_COLOR_PALLETTE } from "constants/colors";

export default function ColorInput({
  field: { onChange, onBlur, name, value, ref },
  fieldState: { invalid, isTouched, isDirty },
  formState: { errors },
  className,
  ...rest
}) {
  const errorMessage = errors[name]?.message;

  return (
    <div
      className={classNames("flex flex-col items-start", className)}
      {...rest}
    >
      <ul className="grid grid-cols-4 p-2 border border-gray-300 rounded-md shadow-sm gap-x-2 gap-y-0.5 sm:grid-cols-6">
        {INVESTMENTS_COLOR_PALLETTE.map((color) => (
          <li
            key={color}
            className="flex justify-center col-span-1 cursor-pointer"
            onClick={() => onChange(color)}
          >
            <div
              className={classNames(
                "w-9 h-9 rounded-full flex justify-center items-center border-2 ",
                {
                  "hover:border-black border-black": color === value,
                  "border-transparent hover:border-gray-400": color !== value,
                }
              )}
            >
              <div
                className="rounded-full w-7 h-7"
                style={{ backgroundColor: color }}
              />
            </div>
          </li>
        ))}
      </ul>
      {errorMessage && (
        <p className="mt-1 text-xs text-red-600">{errorMessage}</p>
      )}
    </div>
  );
}

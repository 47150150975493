import { useState } from "react";
import { FolderAddIcon } from "@heroicons/react/outline";
import { PlusCircleIcon as PlusCircleIconSolid } from "@heroicons/react/solid";

import Button, { TextButton } from "components/Button";
import Modal from "components/Modal";
import Loaders from "components/Loaders";
import Currency from "components/Currency";
import useAccounts from "hooks/data/useAccounts";
import * as Accountant from "services/accountant";

import CreateAccountModal from "./CreateAccountModal";
import AccountsList from "./AccountsList";

export default function AccountsCard({ displayClosedAccounts = false }) {
  const { accounts, loading, error } = useAccounts();
  const [showNewAccountModal, setShowNewAccountModal] = useState(false);

  let netAccounts;
  if (!loading && accounts) {
    netAccounts = Accountant.netAccounts({ accounts });
  }

  return (
    <>
      <div className="flex-col overflow-hidden bg-white border rounded-sm shadow-sm border-gray-50">
        <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
          <div className="flex flex-wrap items-center justify-between -mt-2 -ml-4 sm:flex-nowrap">
            <div className="flex flex-col items-stretch mt-2 ml-4 space-y-1">
              <h3 className="text-xl font-medium leading-6 text-gray-900">
                Accounts
              </h3>
              {netAccounts ? (
                <div className="flex items-center space-x-2 text-xs text-gray-600">
                  <div className="flex py-1 pr-2 space-x-1">
                    <span>Total:</span>
                    <Currency
                      className="font-semibold text-green-800"
                      value={netAccounts}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <div className="flex-shrink-0 mt-2 ml-4">
              <Button onClick={() => setShowNewAccountModal(true)}>
                <PlusCircleIconSolid className="self-center w-4 h-4 mr-2 text-white" />
                Add Account
              </Button>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="flex justify-center py-12">
            <Loaders.Spinner className="w-6 h-6 text-indigo-600" />
          </div>
        ) : accounts && accounts.length ? (
          <AccountsList
            accounts={accounts.filter(
              (account) => displayClosedAccounts || !account.closed
            )}
          />
        ) : (
          <div className="flex-col py-12 text-center">
            <FolderAddIcon className="w-10 h-10 mx-auto text-gray-400" />
            <h3 className="mt-2 text-lg font-medium text-gray-900">
              No accounts
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              Penny helps you track your financial state via investments,
              savings & expenses.
            </p>
            <p className="text-sm text-gray-500">
              <TextButton onClick={() => setShowNewAccountModal(true)}>
                Add an account
              </TextButton>{" "}
              to get started.
            </p>
          </div>
        )}
      </div>
      <Modal
        open={showNewAccountModal}
        onClose={() => setShowNewAccountModal(false)}
        content={
          <CreateAccountModal close={() => setShowNewAccountModal(false)} />
        }
      />
    </>
  );
}

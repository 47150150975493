import { useCallback } from "react";
import { useForm } from "react-hook-form";

import { useAuthProvider } from "components/AuthProvider";
import * as InvestmentsApis from "apis/investments";
import { INVESTMENTS_COLOR_PALLETTE } from "constants/colors";

export default function useCreateInvestment({ investments, onCreateSuccess }) {
  const authProvider = useAuthProvider();
  const form = useForm({
    defaultValues: {
      name: "",
      targetXirr: "",
      color: INVESTMENTS_COLOR_PALLETTE.filter(
        (color) => investments.map((i) => i.color).indexOf(color) === -1
      )[0],
      invested: {
        currency: authProvider.settings.currency,
        value: "",
      },
      currentValue: {
        currency: authProvider.settings.currency,
        value: "",
      },
      liquidity: {
        currency: authProvider.settings.currency,
        value: "",
      },
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      try {
        const investment = await InvestmentsApis.create({
          userId: authProvider.currentUser.uid,
          name: data.name,
          targetXirr: data.targetXirr,
          invested: data.invested,
          currentValue: data.currentValue,
          liquidity: data.liquidity,
          color: data.color,
        });

        if (typeof onCreateSuccess === "function") {
          onCreateSuccess(investment);
        }
      } catch (err) {
        console.log(err, "erorr!");
      }
    },
    [authProvider, onCreateSuccess]
  );

  return {
    form,
    handleSubmit: form.handleSubmit(onSubmit),
  };
}

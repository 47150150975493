import { useState } from "react";
import { subMonths, subDays } from "date-fns";

import * as Accountant from "services/accountant";
import useExpenses from "hooks/data/useExpenses";

const TODAY = new Date();

export default function useExpensesSummary() {
  const [timeRange, setTimeRange] = useState(TIME_RANGE_OPTIONS[0]);
  const { expenses, loading: expensesLoading } = useExpenses();
  const loading = expensesLoading;

  return {
    timeRange,
    setTimeRange: (value) =>
      setTimeRange(TIME_RANGE_OPTIONS.filter((o) => o.value === value)[0]),
    loading,
    expensesByTags: !loading
      ? Accountant.groupExpensesByTags({ expenses }, timeRange)
      : null,
  };
}

export const TIME_RANGE_OPTIONS = [
  {
    label: "Last 30 days",
    value: "30d",
    start: subDays(TODAY, 30),
    end: TODAY,
  },
  {
    label: "Last 3 months",
    value: "3m",
    start: subMonths(TODAY, 3),
    end: TODAY,
  },
  {
    label: "Last 6 months",
    value: "6m",
    start: subMonths(TODAY, 6),
    end: TODAY,
  },
  {
    label: "Last 12 months",
    value: "12m",
    start: subMonths(TODAY, 12),
    end: TODAY,
  },
];

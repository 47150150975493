import Link from "components/Link";
import useBreadcrumbs from "use-react-router-breadcrumbs";

import useBreadcrumbResource from "./hooks/useBreadcrumbResource";

export default function Breadcrumbs() {
  const breadcrumbs = useBreadcrumbs([
    {
      path: "investments/:investmentId",
      breadcrumb: getResourceBreadcrumb({
        collection: "investments",
        nameKey: "name",
        segmentKey: "investmentId",
      }),
    },
    {
      path: "accounts/:accountId",
      breadcrumb: getResourceBreadcrumb({
        collection: "accounts",
        nameKey: "name",
        segmentKey: "accountId",
      }),
    },
  ]);

  return (
    <nav className="flex mb-3" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-1">
        {breadcrumbs.map(({ match, breadcrumb }, index) => {
          return (
            <li key={`${match.pathname}${index}`}>
              <div className="flex items-center">
                <Link
                  to={match}
                  className="mr-1 text-xs"
                  activeClassName={"text-gray-900 hover:text-black"}
                  inactiveClassName={"text-gray-500 hover:text-gray-700"}
                >
                  {breadcrumb}
                </Link>
                {index === breadcrumbs.length - 1 ? null : (
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-gray-300"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                )}
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
}

function getResourceBreadcrumb({ collection, segmentKey, nameKey }) {
  function ResourceBreadcrumb({ match }) {
    const value = useBreadcrumbResource({
      collection,
      id: match.params[segmentKey],
    });

    return value ? value[nameKey] : null;
  }

  return ResourceBreadcrumb;
}

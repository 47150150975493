import { format as formatDate } from "date-fns";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getMessaging } from "firebase/messaging";
import { getFirestore } from "firebase/firestore";
import { FIREBASE_ERRORS } from "constants/errors";

const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_GMEASUREMENT_ID,
};

export const firebaseApp = initializeApp(FIREBASE_CONFIG);
export const firebaseAnalytics = getAnalytics(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
export const firebaseMessaging = getMessaging(firebaseApp);
export const firebaseStore = getFirestore(firebaseApp);

export const firebaseErrorDescription = (err) => {
  if (err.constructor.name === "FirebaseError") {
    return FIREBASE_ERRORS[err.code] || "";
  }
};

export function formatTimestamp(timestamp, format) {
  return timestamp && timestamp.seconds
    ? formatDate(new Date(timestamp.seconds * 1000), format)
    : "";
}

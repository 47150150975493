import { useEffect, useState } from "react";

import { FALLBACK_EXCHANGE_RATES } from "constants/currencies";
import * as Accountant from "services/accountant";

export default function useExchangeRates() {
  const [exchangeRates, setExchangeRates] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function init() {
      let rates;
      try {
        const response = await fetch(
          "https://freecurrencyapi.net/api/v2/latest?apikey=edb8e790-80e9-11ec-b216-05c50d27628b"
        );
        rates = await response.json();
      } catch (err) {
        console.warn("Error in fetching exchange rates! Using fallback rates");
        rates = FALLBACK_EXCHANGE_RATES;
      }

      rates.data[rates.query.base_currency] = 1;

      setExchangeRates(rates);
      Accountant.setContext({ exchangeRates: rates.data });
      setLoading(false);
    }

    init();
  }, []);

  return {
    loading,
    data: exchangeRates,
  };
}

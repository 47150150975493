import React, { useRef, useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import classNames from "classnames";
import { format as formatDate } from "date-fns";
import {
  SortAscendingIcon,
  SortDescendingIcon,
} from "@heroicons/react/outline";
import toast from "react-hot-toast";

import Currency from "components/Currency";
import { useAuthProvider } from "components/AuthProvider";
import * as Accountant from "services/accountant";
import * as Browser from "services/browser";
import { TextButton } from "components/Button";
import Notification from "components/Notification";
import useExpandList from "hooks/useExpandList";
import useExpenseRemove from "./hooks/useExpenseRemove";

const INITIAL_VISIBLE_ROWS_LENGTH = Browser.getScreen() === "mobile" ? 6 : 20;

export default function ExpensesList({ expenses, onExpenseEdit }) {
  const listRef = useRef(null);
  const authProvider = useAuthProvider();
  const { removeExpense, isRemovingExpense } = useExpenseRemove();
  const columns = useMemo(() => {
    return [
      {
        id: "date",
        Header: "Date",
        accessor: (row) => formatDate(new Date(row.timestamp), "MMM dd, yyyy"),
        sortDescFirst: true,
        sortType: (r1, r2) => r1.original.timestamp - r2.original.timestamp,
      },
      {
        accessor: "amount",
        Header: "Amount",
        Cell: ({ value }) => <Currency value={value} />,
        sortDescFirst: true,
        sortType: (r1, r2) =>
          Accountant.sortCurrency(r1.original.amount, r2.original.amount),
      },
      {
        Header: "Actions",
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="flex flex-row items-center space-x-3 text-sm">
            <TextButton
              variant="primary"
              onClick={(event) => {
                event.stopPropagation();
                onExpenseEdit(row.original);
              }}
            >
              Edit
            </TextButton>
            <TextButton
              variant="secondary"
              onClick={(event) => {
                event.stopPropagation();
                if (
                  window.confirm(
                    "Are you sure you want to delete this expense?"
                  )
                ) {
                  removeExpense({
                    expense: row.original,
                    onSuccess: () => {
                      toast.custom((t) => (
                        <Notification
                          toast={t}
                          variant="success"
                          content={
                            <p className="text-sm font-medium text-gray-900">
                              Expense has been deleted successfully.
                            </p>
                          }
                        />
                      ));
                    },
                    onError: (err) => {
                      toast.custom((t) => (
                        <Notification
                          toast={t}
                          variant="error"
                          content={
                            <p className="text-sm font-medium ">
                              There was an error in deleting the expense!
                            </p>
                          }
                        />
                      ));
                    },
                  });
                }
              }}
            >
              Delete
            </TextButton>
          </div>
        ),
      },
      {
        accessor: "reason",
        Header: "Remarks",
        disableSortBy: true,
      },
    ];
  }, []);

  const sortBy = useMemo(() => {
    return [
      {
        id: authProvider.settings.cards.expensesList.sortKey,
        desc: authProvider.settings.cards.expensesList.sortDesc,
      },
    ];
  }, [authProvider.settings]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    setPageSize,
  } = useTable(
    {
      data: expenses,
      columns,
      initialState: {
        sortBy,
        pageSize: INITIAL_VISIBLE_ROWS_LENGTH,
        pageIndex: 0,
      },
      disableSortRemove: true,
    },
    useSortBy,
    // useGroupBy,
    // useFilters,
    // useExpanded,
    usePagination
  );

  const expandList = useExpandList({
    visible: INITIAL_VISIBLE_ROWS_LENGTH < expenses.length,
    initialExpanded: false,
    onExpand: () => setPageSize(expenses.length + 1),
    onShrink: () => {
      setPageSize(INITIAL_VISIBLE_ROWS_LENGTH);
      requestAnimationFrame(() => {
        listRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
      });
    },
  });

  function onColumnSort({ id, desc }) {
    authProvider.updateSettings("cards.expensesList", {
      sortKey: id,
      sortDesc: desc,
    });
  }

  return (
    <div ref={listRef} className="relative">
      <div className="overflow-x-auto">
        <table
          className="min-w-full divide-y divide-gray-100"
          {...getTableProps()}
        >
          <thead className="bg-gray-50">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  const { onClick: originalOnClick, ...rest } =
                    column.getSortByToggleProps();

                  const columnSortByProps = {
                    ...rest,
                    onClick: (event) => {
                      if (typeof originalOnClick === "function") {
                        onColumnSort({
                          id: column.id,
                          desc: !column.isSortedDesc,
                        });
                        originalOnClick(event);
                      }
                    },
                  };

                  return (
                    <th
                      {...column.getHeaderProps(columnSortByProps)}
                      className={classNames(
                        "px-4 md:px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase",
                        { "hover:bg-gray-100": column.canSort }
                      )}
                      style={{
                        minWidth: 150,
                      }}
                    >
                      <span className="relative pr-3">
                        {column.render("Header")}
                        {column.canSort && (
                          <span className="absolute top-0 -right-3">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <SortDescendingIcon className="w-3.5 h-3.5 text-gray-400" />
                              ) : (
                                <SortAscendingIcon className="w-3.5 h-3.5 text-gray-400" />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        )}
                      </span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={classNames(
                    "cursor-pointer hover:bg-gray-50 bg-white",
                    {
                      "border-b border-gray-100": index < rows.length - 1,
                      "opacity-40 cursor-wait pointer-events-none":
                        isRemovingExpense(row.original.id),
                    }
                  )}
                  onClick={() => {
                    onExpenseEdit(row.original);
                  }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={
                          "align-top px-4 md:px-6 py-4 whitespace-nowrap"
                        }
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {expandList.visible ? (
        <div className="p-2 text-xs font-medium text-center text-gray-600 bg-gray-50">
          <span
            className="px-4 py-2 cursor-pointer"
            onClick={expandList.toggle}
          >
            {expandList.message}
          </span>
        </div>
      ) : null}
    </div>
  );
}

import React, { useMemo } from "react";
import { Chart } from "react-charts";

import Loaders from "components/Loaders";
import useMonthlyCashflow, {
  TIME_RANGE_OPTIONS,
} from "./hooks/useMonthlyCashflow";

export default function MonthlyCashflowCard() {
  const monthlyCashflow = useMonthlyCashflow();

  return (
    <div className="flex-col overflow-hidden bg-white border rounded-sm shadow-sm border-gray-50">
      <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
        <div className="flex flex-wrap items-center justify-between -mt-2 -ml-4 sm:flex-nowrap">
          <div className="flex flex-col items-stretch mt-2 ml-4 space-y-1">
            <h3 className="text-xl font-medium leading-6 text-gray-900">
              Monthly Cashflows
            </h3>
          </div>
          <div className="flex-shrink-0 mt-2 ml-4">
            <select
              id="monthlySeriesCashflowGroupBy"
              name="monthlySeriesCashflowGroupBy"
              className="mb-2 cursor-pointer block py-0.5 ml-auto mx-3 pr-8 text-sm border-none bg-transparent rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              value={monthlyCashflow.timeRange.value}
              onChange={(e) => monthlyCashflow.setTimeRange(e.target.value)}
            >
              {TIME_RANGE_OPTIONS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {monthlyCashflow.loading ? (
        <div className="flex justify-center py-12">
          <Loaders.Spinner className="w-6 h-6 text-indigo-600" />
        </div>
      ) : monthlyCashflow.data && monthlyCashflow.data.length ? (
        <MonthlyCashflowChart
          data={monthlyCashflow.data}
          timeRange={monthlyCashflow.timeRange}
          setTimeRange={monthlyCashflow.setTimeRange}
        />
      ) : (
        <div className="flex-col py-12 text-center">No cashflow data</div>
      )}
    </div>
  );
}

function MonthlyCashflowChart({ data }) {
  const primaryAxis = useMemo(
    () => ({
      getValue: (datum) => datum.primary,
    }),
    []
  );
  const secondaryAxes = useMemo(
    () => [
      {
        getValue: (datum) => datum.secondary,
      },
    ],
    []
  );

  return (
    <div className="flex-col py-6">
      <div className="mx-6" style={{ minHeight: 200 }}>
        <Chart
          options={{
            data,
            primaryAxis,
            secondaryAxes,
          }}
        />
      </div>
    </div>
  );
}

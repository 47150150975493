import React from "react";

import UpdateNotificationsCard from "containers/Layout/UpdateNotificationsCard";

import InvestmentsCard from "./InvestmentsCard";
import AccountsCard from "./AccountsCard";
import CashflowCard from "./CashflowCard";

export default function HomeContainer() {
  return (
    <div className="flex flex-col">
      <div className="block mb-6 -mx-6 lg:hidden">
        <UpdateNotificationsCard />
      </div>
      <div className="flex flex-col space-y-9">
        <InvestmentsCard />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-9 md:gap-y-0">
          <CashflowCard />
          <AccountsCard />
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";

import Button from "components/Button";
import Currency from "components/Currency";
import Modal from "components/Modal";
import CreateGoalModal from "containers/SetupContainer/CreateGoalModal";
import useGoals from "hooks/data/useGoals";
import Loaders from "components/Loaders";
import { useAuthProvider } from "components/AuthProvider";

export default function GoalsCard() {
  const authProvider = useAuthProvider();
  const { goals, loading, error } = useGoals();
  const [showCreateGoalModal, setShowCreateGoalModal] = useState(false);

  return (
    <div className="flex flex-col items-center">
      {loading ? (
        <Loaders.Spinner />
      ) : (
        <ul className="divide-y divide-gray-200">
          {goals.map((goal) => (
            <li key={goal.id} className="flex py-4">
              <div className="ml-3">
                <p className="text-sm font-medium text-gray-900">
                  {goal.title}
                </p>
                <p className="text-sm text-gray-500">
                  <Currency value={goal.targetAmount} />
                </p>
              </div>
            </li>
          ))}
        </ul>
      )}
      <Button onClick={() => setShowCreateGoalModal(true)}>Add goal</Button>
      <Button
        onClick={async () => {
          await authProvider.updateSettings("onboardingCompleted", true);
          window.location.href = "/";
        }}
      >
        Setup Done
      </Button>
      <Modal
        open={showCreateGoalModal}
        onClose={() => setShowCreateGoalModal(false)}
        content={
          <CreateGoalModal close={() => setShowCreateGoalModal(false)} />
        }
      />
    </div>
  );
}

import React from "react";
import { Controller } from "react-hook-form";

import Input from "./Input";
import Currency from "./Currency";
import Color from "./Color";
import DateInput from "./Date";

export default function FormField({
  controlType,
  control,
  name,
  rules = {},
  ...rest
}) {
  const Component = CONTROL_TYPE_TO_COMPONENT_MAP[controlType];
  if (!Component) {
    return null;
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={(props) => <Component {...props} {...rest} />}
    />
  );
}

const CONTROL_TYPE_TO_COMPONENT_MAP = {
  input: Input,
  currency: Currency,
  color: Color,
  date: DateInput,
};

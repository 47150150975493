import {
  endOfMonth,
  format as formatDate,
  parse as parseDate,
  addDays,
  differenceInDays,
} from "date-fns";
import { xirr, convertRate } from "node-irr";

import { _reverse } from "services/lodash";
import { ENTRY_DATE_FORMAT } from "constants/dates";

let context = {
  // TODO: Check why accountant crashes when defaults are not provided
  preferredCurrency: "INR",
  exchangeRates: {
    USD: 1,
    INR: 75.03,
  },
};

export function setContext(contextUpdate) {
  context = {
    ...context,
    ...contextUpdate,
  };
}

export function portfolioProfitAndLoss({ invested, current }) {
  const { value: investedValue, currency: investedCurrency } = invested;
  const { value: currentValue, currency: currentValueCurrency } = current;

  const absolute =
    convert({ fromCurrency: currentValueCurrency, fromValue: currentValue }) -
    convert({
      fromCurrency: investedCurrency,
      fromValue: investedValue,
    });

  return {
    absolute: { value: absolute, currency: context.preferredCurrency },
    percentage: (
      (absolute * 100) /
      convert({
        fromCurrency: investedCurrency,
        fromValue: investedValue,
      })
    ).toFixed(2),
  };
}

export function sortCurrency(currencyA, currencyB) {
  // TODO: Handle different currencies
  const delta =
    convert({ fromCurrency: currencyA.currency, fromValue: currencyA.value }) -
    convert({ fromCurrency: currencyB.currency, fromValue: currencyB.value });

  if (delta > 0) {
    return 1;
  } else if (delta < 0) {
    return -1;
  } else {
    return 0;
  }
}

export function totalInvested(investments, options) {
  return investments
    .filter((investment) => !investment.closed)
    .reduce((total, investment) => {
      let entry = investment.entries[0];
      if (options && options.date) {
        entry = findNearestEntry({
          entries: investment.entries,
          date: options.date,
        });
      }

      return entry
        ? total +
            parseFloat(
              convert({
                fromCurrency: entry.invested.currency,
                fromValue: entry.invested.value,
              })
            )
        : 0;
    }, 0);
}

export function totalCurrentValue(investments, options) {
  return investments
    .filter((investment) => !investment.closed)
    .reduce((total, investment) => {
      let entry = investment.entries[0];
      if (options && options.date) {
        entry = findNearestEntry({
          entries: investment.entries,
          date: options.date,
        });
      }

      return entry
        ? total +
            parseFloat(
              convert({
                fromCurrency: entry.currentValue.currency,
                fromValue: entry.currentValue.value,
              })
            )
        : 0;
    }, 0);
}

export function totalAccountsValue(accounts, options = {}) {
  return accounts
    .filter((account) => !account.closed)
    .reduce((total, account) => {
      let entry = account.entries[0];
      if (options && options.date) {
        entry = findNearestEntry({
          entries: account.entries,
          date: options.date,
        });
      }

      return entry
        ? total +
            parseFloat(
              convert({
                fromCurrency: entry.currentValue.currency,
                fromValue: entry.currentValue.value,
              })
            )
        : 0;
    }, 0);
}

export function investmentWeightage({ investment, allInvestments }) {
  return parseFloat(
    (convert({
      fromCurrency: investment.invested.currency,
      fromValue: investment.invested.value,
    }) *
      100) /
      totalInvested(allInvestments)
  ).toFixed(2);
}

export function convert({ fromCurrency, fromValue, toCurrency }) {
  toCurrency = toCurrency || context.preferredCurrency;

  return Number(
    (
      (Number(fromValue) * context.exchangeRates[toCurrency]) /
      context.exchangeRates[fromCurrency]
    ).toFixed(2)
  );
}

export function currentNetWorth({ investments, accounts }) {
  return {
    total: {
      currency: context.preferredCurrency,
      value: totalAccountsValue(accounts) + totalCurrentValue(investments),
    },
    investments: {
      currency: context.preferredCurrency,
      value: totalCurrentValue(investments),
    },
    savings: {
      currency: context.preferredCurrency,
      value: totalAccountsValue(accounts),
    },
  };
}

export function netWorthSeries({ investments, accounts, startAt, endAt }) {
  const entries = [];

  let i = startAt;
  while (i.getTime() <= endAt.getTime()) {
    const investmentsOnDay = {
      currency: context.preferredCurrency,
      value: totalCurrentValue(investments, { date: i }),
    };
    const investedAmountOnDay = {
      currency: context.preferredCurrency,
      value: totalInvested(investments, { date: i }),
    };
    const savingsOnDay = {
      currency: context.preferredCurrency,
      value: totalAccountsValue(accounts, { date: i }),
    };
    const totalOnDay = {
      currency: context.preferredCurrency,
      value:
        totalAccountsValue(accounts, { date: i }) +
        totalCurrentValue(investments, { date: i }),
    };

    entries.push({
      date: i,
      value: {
        investments: investmentsOnDay,
        investedAmounts: investedAmountOnDay,
        savings: savingsOnDay,
        total: totalOnDay,
      },
    });

    i = addDays(i, 1);
  }

  window._netWorthSeries = entries;
  return entries;
}

export function cashflowData(
  { expenses, incomes, investments, accounts }, // data
  { start, end } // time range to compute cashflow in
) {
  const accountedExpensesAmount = netExpense({
    expenses: expenses.filter((expense) =>
      expenseInRange({ expense, start, end })
    ),
  });

  const netIncomesAmount = netIncome({
    incomes: incomes.filter((income) => incomeInRange({ income, start, end })),
  });

  const changeInAccountsValue = accounts.reduce((value, account) => {
    const inRangeEntries = account.entries.filter(
      (entry) =>
        entry.timestamp >= start.getTime() && entry.timestamp <= end.getTime()
    );

    let changeInAccountValue = 0;
    if (inRangeEntries.length > 0) {
      changeInAccountValue =
        convert({
          fromCurrency: inRangeEntries[0].currentValue.currency,
          fromValue: inRangeEntries[0].currentValue.value,
        }) -
        convert({
          fromCurrency:
            inRangeEntries[inRangeEntries.length - 1].currentValue.currency,
          fromValue:
            inRangeEntries[inRangeEntries.length - 1].currentValue.value,
        });
    }

    return value + changeInAccountValue;
  }, 0);

  const changeInInvestedAmountsValue = investedAmountInRange({
    investments,
    start,
    end,
  });

  const changeInInvestmentLiquidityValue = investments.reduce(
    (value, investment) => {
      const inRangeEntries = investment.entries.filter(
        (entry) =>
          entry.timestamp >= start.getTime() && entry.timestamp <= end.getTime()
      );

      let changeInInvestmentValue = 0;
      if (inRangeEntries.length) {
        changeInInvestmentValue =
          convert({
            fromCurrency: inRangeEntries[0].liquidity.currency,
            fromValue: inRangeEntries[0].liquidity.value,
          }) -
          convert({
            fromCurrency:
              inRangeEntries[inRangeEntries.length - 1].liquidity.currency,
            fromValue:
              inRangeEntries[inRangeEntries.length - 1].liquidity.value,
          });
      }

      return value + changeInInvestmentValue;
    },
    0
  );

  return {
    expenses: {
      accounted: {
        value: accountedExpensesAmount,
        currency: context.preferredCurrency,
      },
      // unaccounted: {
      //   value: unaccountedExpensesAmount,
      //   currency: context.preferredCurrency,
      // },
      // total: {
      //   value: totalExpenses,
      //   currency: context.preferredCurrency,
      // },
    },
    investments: {
      invested: {
        value: changeInInvestedAmountsValue,
        currency: context.preferredCurrency,
      },
      liquidity: {
        value: changeInInvestmentLiquidityValue,
        currency: context.preferredCurrency,
      },
    },
    accounts: {
      value: changeInAccountsValue,
      currency: context.preferredCurrency,
    },
    income: {
      value: netIncomesAmount,
      currency: context.preferredCurrency,
    },
  };
}

export function netInvestments({ investments }) {
  if (!investments) {
    return {};
  }

  const invested = totalInvested(investments);
  const currentValue = totalCurrentValue(investments);
  const netProfit = ((currentValue - invested) * 100) / invested;

  return {
    total: {
      currency: context.preferredCurrency,
      value: currentValue,
    },
    netProfitAndLoss: netProfit,
  };
}

export function netAccounts({ accounts }) {
  if (!accounts) {
    return {};
  }

  return {
    currency: context.preferredCurrency,
    value: totalAccountsValue(accounts),
  };
}

function findNearestEntry({ entries, date }) {
  let entry;
  let i = entries.length - 1;
  while (i >= 0) {
    entry = entries[i];
    const entryDate = parseDate(entry.date, ENTRY_DATE_FORMAT, new Date());

    if (
      formatDate(entryDate, ENTRY_DATE_FORMAT) ===
      formatDate(date, ENTRY_DATE_FORMAT)
    ) {
      break;
    } else if (entryDate > date) {
      entry = entries[Math.min(i + 1, entries.length - 1)];
      break;
    }

    i--;
  }

  return entry;
}
window._findNearestEntry = findNearestEntry;

// Use in-memory map to avoid repeat runs
const investmentXirrMap = {};
export function investmentXirr({
  investment,
  start,
  end,
  forceCalculate = false,
}) {
  if (investmentXirrMap[investment.id] && !forceCalculate) {
    return investmentXirrMap[investment.id];
  }

  const entries = _reverse(investment.entries);
  const transactions = [
    ...entries
      .filter((entry) => {
        return new Date().getTime() - entry.timestamp > 24 * 60 * 60 * 1000;
      })
      .map((entry, index) => {
        let prevEntryAmount = 0;
        if (index > 0) {
          prevEntryAmount = parseFloat(entries[index - 1].invested.value);
        }
        return {
          amount: -1 * (parseFloat(entry.invested.value) - prevEntryAmount),
          date: new Date(entry.timestamp),
        };
      })
      .filter((transaction) => transaction.amount !== 0),
    {
      amount: parseFloat(investment.currentValue.value),
      date: new Date(),
    },
  ];

  const data = xirr(transactions);
  const annualRate = convertRate(data.rate, 365) * 100;
  investmentXirrMap[investment.id] = annualRate.toFixed(2);

  return investmentXirrMap[investment.id];
}

export function cashflowSeries(
  investmentData, // data
  { start, end, groupBy = "month" } // time range to compute cashflow in and group entries by
) {
  let groups = [];
  let time = start;
  while (time.getTime() <= end.getTime()) {
    groups.push({
      start: time,
      end: endOfMonth(time),
    });
    time = addDays(endOfMonth(time), 1);
  }

  groups = groups.map((group) => ({
    data: cashflowData(investmentData, { start: group.start, end: group.end }),
    ...group,
  }));

  return [
    {
      label: "New Investments",
      data: groups.map((group) => ({
        primary: formatDate(group.start, "MMM yyyy"),
        secondary: group.data.investments.invested.value,
      })),
    },
    {
      label: "Income",
      data: groups.map((group) => ({
        primary: formatDate(group.start, "MMM yyyy"),
        secondary: group.data.income.value,
      })),
    },
    {
      label: "Expenses",
      data: groups.map((group) => ({
        primary: formatDate(group.start, "MMM yyyy"),
        secondary: group.data.expenses.accounted.value,
      })),
    },
  ];
}

const HASHTAG_MATCH_REGEX = /([#|＃][^\s]+)/g;
export function groupExpensesByTags(
  { expenses },
  { start = new Date(0), end = new Date() }
) {
  const expensesInRange = expenses.filter(
    (expense) =>
      expense.timestamp >= start.getTime() && expense.timestamp <= end.getTime()
  );

  const hashTagToExpenseMap = {};
  expensesInRange.forEach((expense) => {
    const hashTags = expense.reason
      .toLowerCase()
      .split(HASHTAG_MATCH_REGEX)
      .filter((k) => k.match(HASHTAG_MATCH_REGEX));

    hashTags.forEach((tag) => {
      hashTagToExpenseMap[tag] = hashTagToExpenseMap[tag] || { expenses: [] };
      hashTagToExpenseMap[tag].expenses.push(expense);
    });
  });

  Object.keys(hashTagToExpenseMap).forEach((tag) => {
    const { expenses } = hashTagToExpenseMap[tag];
    hashTagToExpenseMap[tag]["total"] = {
      currency: context.preferredCurrency,
      value: expenses.reduce(
        (total, expense) =>
          total +
          parseFloat(
            convert({
              fromCurrency: expense.amount.currency,
              fromValue: expense.amount.value,
            })
          ),
        0
      ),
    };
  });

  return hashTagToExpenseMap;
}

function expenseInRange({ expense, start, end }) {
  return (
    expense.timestamp >= start.getTime() && expense.timestamp <= end.getTime()
  );
}

function netExpense({ expenses }) {
  return expenses.reduce(
    (total, expense) =>
      total +
      parseFloat(
        convert({
          fromCurrency: expense.amount.currency,
          fromValue: expense.amount.value,
        })
      ),
    0
  );
}

function netIncome({ incomes }) {
  return incomes.reduce(
    (total, income) =>
      total +
      parseFloat(
        convert({
          fromCurrency: income.amount.currency,
          fromValue: income.amount.value,
        })
      ),
    0
  );
}

function incomeInRange({ income, start, end }) {
  return (
    income.timestamp >= start.getTime() && income.timestamp <= end.getTime()
  );
}

function investedAmountInRange({ investments, start, end }) {
  return investments.reduce((value, investment) => {
    const inRangeEntries = investment.entries.filter(
      (entry) =>
        entry.timestamp >= start.getTime() && entry.timestamp <= end.getTime()
    );

    let changeInInvestmentValue = 0;
    if (inRangeEntries.length) {
      changeInInvestmentValue =
        convert({
          fromCurrency: inRangeEntries[0].invested.currency,
          fromValue: inRangeEntries[0].invested.value,
        }) -
        convert({
          fromCurrency:
            inRangeEntries[inRangeEntries.length - 1].invested.currency,
          fromValue: inRangeEntries[inRangeEntries.length - 1].invested.value,
        });
    }

    return value + changeInInvestmentValue;
  }, 0);
}

function netSavingsInRange({ accounts, start, end }) {
  return accounts.reduce((value, account) => {
    const inRangeEntries = account.entries.filter(
      (entry) =>
        entry.timestamp >= start.getTime() && entry.timestamp <= end.getTime()
    );

    let changeInAccountValue = 0;
    if (inRangeEntries.length) {
      changeInAccountValue =
        convert({
          fromCurrency: inRangeEntries[0].currentValue.currency,
          fromValue: inRangeEntries[0].currentValue.value,
        }) -
        convert({
          fromCurrency:
            inRangeEntries[inRangeEntries.length - 1].currentValue.currency,
          fromValue:
            inRangeEntries[inRangeEntries.length - 1].currentValue.value,
        });
    }

    return value + changeInAccountValue;
  }, 0);
}

export function averageDailyExpense({ expenses, timeRange }) {
  const { start, end } = timeRange;
  const netExpenses = netExpense({
    expenses: expenses.filter((expense) =>
      expenseInRange({ expense, start, end })
    ),
  });
  const daysInRange = differenceInDays(end, start);

  return {
    value: Math.round(netExpenses / daysInRange),
    currency: context.preferredCurrency,
  };
}
export function averageDailyIncome({ incomes, timeRange }) {
  const { start, end } = timeRange;
  const netIncomes = netIncome({
    incomes: incomes.filter((income) => incomeInRange({ income, start, end })),
  });
  const daysInRange = differenceInDays(end, start);

  return {
    value: Math.round(netIncomes / daysInRange),
    currency: context.preferredCurrency,
  };
}

export function averageDailyInvestment({ investments, timeRange }) {
  const { start, end } = timeRange;
  const netInvestmentAmount = investedAmountInRange({
    investments,
    start,
    end,
  });
  const daysInRange = differenceInDays(end, start);

  return {
    value: Math.round(netInvestmentAmount / daysInRange),
    currency: context.preferredCurrency,
  };
}

export function averageDailySaving({ accounts, timeRange }) {
  const { start, end } = timeRange;
  const netSavings = netSavingsInRange({
    accounts,
    start,
    end,
  });
  const daysInRange = differenceInDays(end, start);

  return {
    value: Math.round(netSavings / daysInRange),
    currency: context.preferredCurrency,
  };
}

export function compoundInterest({ principal, rate, years }) {
  return principal * Math.pow(1 + rate, years);
}
export function futureValue({ yearlyContribution, rate, years }) {
  return (yearlyContribution * (Math.pow(1 + rate, years) - 1)) / rate;
}

import React from "react";
import toast from "react-hot-toast";

import FormField from "components/Form/Field";
import Button from "components/Button";
import Notification from "components/Notification";
import * as Validator from "services/validator";

import useCreateAccount from "./hooks/useCreateAccount";

export default function CreateAccountModal({ close }) {
  const createAccount = useCreateAccount({
    onCreateSuccess: () => {
      close();
      toast.custom((t) => (
        <Notification
          toast={t}
          variant="success"
          content={
            <p className="text-sm font-medium text-gray-900">
              Account has been added successfully!
            </p>
          }
        />
      ));
    },
  });

  return (
    <section aria-labelledby="create-porfolio-form">
      <form onSubmit={createAccount.handleSubmit}>
        <div className="p-6 bg-white">
          <div>
            <h2
              id="create-porfolio-form-heading"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              Add Account
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              You can track your liquid savings by adding your accounts.
            </p>
          </div>

          <div className="grid grid-cols-4 gap-6 mt-6">
            <div className="col-span-4">
              <label
                htmlFor="name"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <FormField
                name="name"
                type="text"
                controlType="input"
                control={createAccount.form.control}
                rules={{ required: true }}
                id="name"
                autoFocus={true}
                placeholder="Enter name (e.g Axis Bank)"
              />
            </div>
            <div className="col-span-4">
              <label
                htmlFor="interestRate"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                Interest Rate
              </label>
              <FormField
                name="interestRate"
                type="number"
                controlType="input"
                className="hideArrow"
                addons={{
                  trailing: <span className="text-gray-500 sm:text-sm">%</span>,
                }}
                control={createAccount.form.control}
                rules={{
                  validate: (v) =>
                    Validator.validAmount(v) || "Enter valid value",
                }}
                id="interestRate"
                placeholder="2.5%"
              />
            </div>

            <div className="col-span-4">
              <label
                htmlFor="currentValue"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                Current Value
              </label>
              <FormField
                name="currentValue"
                type="number"
                controlType="currency"
                control={createAccount.form.control}
                rules={{
                  validate: (v) =>
                    (v && Validator.validAmount(v.value)) ||
                    "Enter valid value",
                }}
                id="currentValue"
                placeholder="e.g 232,456"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end px-4 py-3 text-right bg-gray-50 sm:px-6">
          <div className="mr-3">
            <Button type="button" variant="secondary" onClick={close}>
              Cancel
            </Button>
          </div>
          <div>
            <Button
              type="submit"
              loading={createAccount.form.formState.isSubmitting}
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </section>
  );
}

import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import {
  SortAscendingIcon,
  SortDescendingIcon,
} from "@heroicons/react/outline";

import Currency from "components/Currency";
import { useAuthProvider } from "components/AuthProvider";
import * as Accountant from "services/accountant";

export default function InvestmentList({ investments }) {
  const navigate = useNavigate();
  const authProvider = useAuthProvider();
  const columns = useMemo(() => {
    return [
      {
        id: "name",
        Header: "Name",
        accessor: (row) => row,
        Cell: ({ value }) => {
          return (
            <div className="flex flex-col items-start">
              <div className="flex items-center mb-1">
                <span className="text-lg font-medium text-gray-900">
                  {value.name}
                </span>
                {value.color ? (
                  <span
                    className="w-3 h-3 ml-2 rounded-full"
                    style={{ backgroundColor: value.color }}
                  />
                ) : null}
              </div>
              <span className="inline-flex font-medium items-center px-2.5 py-0.5 rounded-full text-xs bg-indigo-100 text-indigo-800">
                Target XIRR: <span className="ml-1">{value.targetXirr}%</span>
              </span>
            </div>
          );
        },
        disableSortBy: true,
      },
      {
        accessor: "invested",
        Header: "Invested",
        Cell: ({ value }) => <Currency value={value} />,
        sortDescFirst: true,
        sortType: (r1, r2) =>
          Accountant.sortCurrency(r1.original.invested, r2.original.invested),
      },
      {
        accessor: "currentValue",
        Header: "Current",
        Cell: ({ value }) => <Currency value={value} />,
        sortDescFirst: true,
        sortType: (r1, r2) =>
          Accountant.sortCurrency(
            r1.original.currentValue,
            r2.original.currentValue
          ),
      },
      {
        id: "pnl",
        sortDescFirst: true,
        sortType: ({ original: r1 }, { original: r2 }) => {
          const { absolute: v1 } = Accountant.portfolioProfitAndLoss({
            invested: r1.invested,
            current: r1.currentValue,
          });
          const { absolute: v2 } = Accountant.portfolioProfitAndLoss({
            invested: r2.invested,
            current: r2.currentValue,
          });

          return Accountant.sortCurrency(v1, v2);
        },
        Header: "P&L",
        accessor: (row) =>
          Accountant.portfolioProfitAndLoss({
            invested: row.invested,
            current: row.currentValue,
          }),
        Cell: ({ value, row }) => {
          const { absolute, percentage } = value;
          const positive = percentage >= 0;

          return (
            <div className="flex flex-col">
              <Currency
                className={classNames("text-base", {
                  "text-green-500": positive,
                  "text-red-500": !positive,
                })}
                value={absolute}
              />
              <span
                className={classNames("text-xs", {
                  "text-green-500": positive,
                  "text-red-500": !positive,
                })}
              >
                {percentage}%
              </span>
            </div>
          );
        },
      },
      {
        id: "weightage",
        Header: "Weightage",
        sortDescFirst: true,
        accessor: (row) => {
          return Accountant.investmentWeightage({
            investment: row,
            allInvestments: investments,
          });
        },
        sortType: (r1, r2) => {
          const r1Weightage = Accountant.investmentWeightage({
            investment: r1.original,
            allInvestments: investments,
          });
          const r2Weightage = Accountant.investmentWeightage({
            investment: r2.original,
            allInvestments: investments,
          });
          return r1Weightage - r2Weightage;
        },
        Cell: ({ value }) => `${value}%`,
      },
      {
        id: "xirr",
        Header: "XIRR",
        sortDescFirst: true,
        accessor: (row) => {
          return Accountant.investmentXirr({
            investment: row,
          });
        },
        sortType: (r1, r2) => {
          const r1Xirr = Accountant.investmentXirr({
            investment: r1.original,
          });
          const r2Xirr = Accountant.investmentXirr({
            investment: r2.original,
          });
          return parseFloat(r1Xirr) - parseFloat(r2Xirr);
        },
        Cell: ({ value }) => {
          const positive = parseFloat(value) > 0;
          return (
            <span
              className={classNames({
                "text-green-500": positive,
                "text-red-500": !positive,
              })}
            >
              {value}%
            </span>
          );
        },
      },
    ];
  }, [investments]);

  const sortBy = useMemo(() => {
    return [
      {
        id: authProvider.settings.cards.investmentsDashboard.sortKey,
        desc: authProvider.settings.cards.investmentsDashboard.sortDesc,
      },
    ];
  }, [authProvider.settings]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        data: investments,
        columns,
        initialState: {
          sortBy,
        },
        disableSortRemove: true,
      },
      useSortBy
      // useGroupBy,
      // useFilters,
      // useExpanded,
      // usePagination
    );

  function onColumnSort({ id, desc }) {
    authProvider.updateSettings("cards.investmentsDashboard", {
      sortKey: id,
      sortDesc: desc,
    });
  }

  return (
    <div className="overflow-x-auto">
      <table
        className="min-w-full divide-y divide-gray-100"
        {...getTableProps()}
      >
        <thead className="bg-gray-50">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                const { onClick: originalOnClick, ...rest } =
                  column.getSortByToggleProps();

                const columnSortByProps = {
                  ...rest,
                  onClick: (event) => {
                    if (typeof originalOnClick === "function") {
                      onColumnSort({
                        id: column.id,
                        desc: !column.isSortedDesc,
                      });
                      originalOnClick(event);
                    }
                  },
                };

                return (
                  <th
                    {...column.getHeaderProps(columnSortByProps)}
                    scope="col"
                    className={classNames(
                      "px-2 md:px-6 pl-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase",
                      { "hover:bg-gray-100": column.canSort }
                    )}
                    style={{ minWidth: 150 }}
                  >
                    <span className="relative pr-6">
                      {column.render("Header")}
                      <span className="absolute top-0 right-0">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <SortDescendingIcon className="w-3.5 h-3.5 text-gray-400" />
                          ) : (
                            <SortAscendingIcon className="w-3.5 h-3.5 text-gray-400" />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={classNames(
                  "cursor-pointer hover:bg-gray-50 bg-white",
                  {
                    "border-b border-gray-100": index < rows.length - 1,
                    "opacity-50": row.original.closed,
                  }
                )}
                onClick={() => {
                  navigate(`/investments/${row.original.id}`);
                }}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={"align-top px-6 py-4 whitespace-nowrap"}
                      width={cell.column.id === "name" ? "38%" : "auto"}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

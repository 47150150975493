import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import classNames from "classnames";
import {
  SortAscendingIcon,
  SortDescendingIcon,
} from "@heroicons/react/outline";

import Currency from "components/Currency";
import { useAuthProvider } from "components/AuthProvider";
import * as Accountant from "services/accountant";

export default function AccountEntriesList({ entries, groupBy }) {
  const authProvider = useAuthProvider();
  const columns = useMemo(() => {
    return [
      {
        id: "date",
        Header: "Date",
        accessor: (row) => row.date,
        sortDescFirst: true,
        sortType: (r1, r2) => r1.original.timestamp - r2.original.timestamp,
      },
      {
        accessor: "currentValue",
        Header: "Current",
        Cell: ({ value }) => <Currency value={value} />,
        sortDescFirst: true,
        sortType: (r1, r2) =>
          Accountant.sortCurrency(
            r1.original.currentValue,
            r2.original.currentValue
          ),
      },
    ];
  }, []);

  const sortBy = useMemo(() => {
    return [
      {
        id: authProvider.settings.cards.accountEntries.sortKey,
        desc: authProvider.settings.cards.accountEntries.sortDesc,
      },
    ];
  }, [authProvider.settings]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        data: entries,
        columns,
        initialState: {
          sortBy,
        },
        disableSortRemove: true,
      },
      useSortBy
      // useGroupBy,
      // useFilters,
      // useExpanded,
      // usePagination
    );

  function onColumnSort({ id, desc }) {
    authProvider.updateSettings("cards.accountEntries", {
      sortKey: id,
      sortDesc: desc,
    });
  }

  return (
    <div className="overflow-x-auto">
      <table
        className="min-w-full divide-y divide-gray-100"
        {...getTableProps()}
      >
        <thead className="bg-gray-50">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                const { onClick: originalOnClick, ...rest } =
                  column.getSortByToggleProps();

                const columnSortByProps = {
                  ...rest,
                  onClick: (event) => {
                    onColumnSort({ id: column.id, desc: !column.isSortedDesc });
                    originalOnClick(event);
                  },
                };

                return (
                  <th
                    {...column.getHeaderProps(columnSortByProps)}
                    scope="col"
                    className={classNames(
                      "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase",
                      { "hover:bg-gray-100": column.canSort }
                    )}
                    style={{ minWidth: 150 }}
                  >
                    <span className="relative pr-6">
                      {column.render("Header")}
                      <span className="absolute top-0 right-0">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <SortDescendingIcon className="w-3.5 h-3.5 text-gray-400" />
                          ) : (
                            <SortAscendingIcon className="w-3.5 h-3.5 text-gray-400" />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={classNames(
                  "cursor-pointer hover:bg-gray-50 bg-white",
                  { "border-b border-gray-100": index < rows.length - 1 }
                )}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={"align-top px-6 py-4 whitespace-nowrap"}
                      width={cell.column.id === "date" ? "38%" : "auto"}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export function getScreen() {
  const width = window.outerWidth;
  if (width > 960) {
    return "desktop";
  } else if (width > 480) {
    return "tablet";
  } else {
    return "mobile";
  }
}

import { useEffect, useState } from "react";

import EntriesList from "./EntriesList";

export default function EntriesCard({ account }) {
  const [groupBy, setGroupBy] = useState(GROUP_BY_OPTIONS[0].value);
  const { entries } = account;

  useEffect(() => {
    console.log("changed gruping to ", groupBy);
  }, [groupBy]);

  return (
    <>
      <div className="flex-col overflow-hidden bg-white border rounded-sm shadow-sm border-gray-50">
        <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
          <div className="flex flex-wrap items-center justify-between -mt-2 -ml-4 sm:flex-nowrap">
            <div className="mt-2 ml-4">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Entries
              </h3>
            </div>
            <select
              id="investmentEntriesGroupBy"
              name="investmentEntriesGroupBy"
              className="mt-2 cursor-pointer block py-0.5 pl-2 pr-8 text-sm border-none bg-transparent rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              value={groupBy}
              onChange={(e) => setGroupBy(e.target.value)}
            >
              {GROUP_BY_OPTIONS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <EntriesList entries={entries} groupBy={groupBy} />
      </div>
    </>
  );
}

const GROUP_BY_OPTIONS = [
  { label: "Group by date", value: "day" },
  { label: "Group by week", value: "week" },
  { label: "Group by month", value: "month" },
];

/*
<Modal
        open={showNewInvestmentModal}
        onClose={() => setShowNewInvestmentModal(false)}
        content={
          <CreateInvestmentModal
            close={() => setShowNewInvestmentModal(false)}
          />
        }
      />
      */

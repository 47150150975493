import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { format as formatDate, parse as parseDate } from "date-fns";

import { useAuthProvider } from "components/AuthProvider";
import * as ExpensesApi from "apis/expenses";

export default function useEditExpense({ expense, onEditSuccess }) {
  const authProvider = useAuthProvider();
  const form = useForm({
    defaultValues: {
      reason: expense?.reason,
      amount: expense?.amount || {
        currency: authProvider.settings.currency,
        value: "",
      },
      date: formatDate(
        expense && expense.timestamp ? new Date(expense.timestamp) : new Date(),
        "dd/MM/yyyy"
      ),
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      try {
        const updatedExpense = await ExpensesApi.update(expense.id, {
          userId: authProvider.currentUser.uid,
          reason: data.reason,
          amount: data.amount,
          timestamp: parseDate(data.date, "dd/MM/yyyyy", new Date()).getTime(),
        });

        if (typeof onEditSuccess === "function") {
          onEditSuccess(updatedExpense);
        }
      } catch (err) {
        console.log(err, "erorr!");
      }
    },
    [authProvider, onEditSuccess, expense]
  );

  return {
    form,
    handleSubmit: form.handleSubmit(onSubmit),
  };
}

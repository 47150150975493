import React, { useState, useCallback } from "react";
import {
  CalendarIcon,
  PencilIcon,
  SaveAsIcon,
  CurrencyDollarIcon,
  TrashIcon,
  LockOpenIcon,
  LockClosedIcon,
} from "@heroicons/react/outline";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import Button from "components/Button";
import Currency from "components/Currency";
import Modal from "components/Modal";
import Notification from "components/Notification";
import { formatTimestamp } from "services/firebase";
import * as AccountApis from "apis/accounts";

import EditAccountModal from "./EditAccountModal";
import RecordUpdateModal from "./RecordUpdateModal";

export default function AccountHeader({ account }) {
  const [showRecordUpdateModal, setShowRecordUpdateModal] = useState(false);
  const [showEditInvestmentModal, setShowEditInvestmentModal] = useState(false);
  const navigate = useNavigate();

  return (
    <header className="pb-6 border-b border-gray-200">
      <div className="mx-auto xl:flex xl:items-center xl:justify-between">
        <div className="flex-1 min-w-0">
          <div className="flex items-center">
            <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              {account.name}
            </h1>
          </div>
          <div className="flex flex-col mt-2 sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
            {account.createdAt ? (
              <div className="flex items-center mt-2 text-sm text-gray-500">
                <CalendarIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                Added on{" "}
                <span className="ml-1 font-medium text-gray-600">
                  {formatTimestamp(account.createdAt, "dd MMMM, yyyy")}
                </span>
              </div>
            ) : null}
            <div className="flex items-center mt-2 text-sm text-gray-500">
              <CurrencyDollarIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              Current Value:{" "}
              <Currency
                className="ml-1 font-medium text-gray-600"
                value={account.currentValue}
              />
            </div>
          </div>
        </div>
        <div className="flex mt-5 xl:mt-0 xl:ml-4">
          <span className="block">
            <Button
              variant="secondary"
              onClick={() => setShowEditInvestmentModal(true)}
            >
              <PencilIcon
                className="w-3.5 h-3.5 sm:w-5 sm:h-5 mr-2 -ml-1 text-gray-400"
                aria-hidden="true"
              />
              Edit
            </Button>
          </span>

          {!account.closed && (
            <span className="block ml-3">
              <Button onClick={() => setShowRecordUpdateModal(true)}>
                <SaveAsIcon
                  className="w-3.5 h-3.5 sm:w-5 sm:h-5 mr-2 -ml-1 text-white"
                  aria-hidden="true"
                />
                Record Update
              </Button>
            </span>
          )}
          <span className="block ml-3">
            <AccountCloseToggle account={account} />
          </span>
          <span className="hidden ml-3">
            <Button
              variant="secondaryRed"
              onClick={async () => {
                const confirmDelete = window.confirm(
                  "Are you sure you want to delete this account? All information will be lost."
                );

                if (confirmDelete) {
                  try {
                    await AccountApis.remove(account.id);
                    navigate(`/`, { replace: true });
                    toast.custom((t) => (
                      <Notification
                        toast={t}
                        variant="success"
                        content={
                          <p className="text-sm font-medium text-gray-900">
                            Account has been deleted.
                          </p>
                        }
                      />
                    ));
                  } catch (err) {
                    console.log(err, "Error in deleting record.");
                  }
                }
              }}
            >
              <TrashIcon
                className="w-5 h-5 mr-2 -ml-1 text-red-600"
                aria-hidden="true"
              />
              Delete
            </Button>
          </span>
        </div>
      </div>
      <Modal
        open={showRecordUpdateModal}
        onClose={() => setShowRecordUpdateModal(false)}
        content={
          <RecordUpdateModal
            account={account}
            close={() => setShowRecordUpdateModal(false)}
          />
        }
      />
      <Modal
        open={showEditInvestmentModal}
        onClose={() => setShowEditInvestmentModal(false)}
        content={
          <EditAccountModal
            account={account}
            close={() => setShowEditInvestmentModal(false)}
          />
        }
      />
    </header>
  );
}

function AccountCloseToggle({ account }) {
  const onClick = useCallback(async () => {
    const isClosed = account.closed;
    const confirmAction = window.confirm(
      `Are you sure you want to ${isClosed ? "re-open" : "close"} this account.`
    );
    if (confirmAction) {
      try {
        await AccountApis.update(account.id, {
          closed: Boolean(!account.closed),
        });
        toast.custom((t) => (
          <Notification
            toast={t}
            variant="success"
            content={
              <p className="text-sm font-medium text-gray-900">
                Account has been updated successfully.
              </p>
            }
          />
        ));
      } catch (err) {
        console.log(err, "error in updating the invesment");
        toast.custom((t) => (
          <Notification
            toast={t}
            variant="error"
            content={
              <p className="text-sm font-medium text-gray-900">
                There was an error in updating the account.
              </p>
            }
          />
        ));
      }
    }
  }, [account.id, account.closed]);

  return (
    <Button
      variant={account.closed ? "secondaryGreen" : "secondaryRed"}
      onClick={onClick}
    >
      {account.closed ? (
        <LockOpenIcon
          aria-hidden="true"
          className="w-3.5 h-3.5 sm:w-5 sm:h-5 mr-2 -ml-1 text-green"
        />
      ) : (
        <LockClosedIcon
          className="w-3.5 h-3.5 sm:w-5 sm:h-5 mr-2 -ml-1 text-red"
          aria-hidden="true"
        />
      )}
      {account.closed ? "Reopen" : "Close"}
    </Button>
  );
}

import React from "react";
import toast from "react-hot-toast";

import FormField from "components/Form/Field";
import Button from "components/Button";
import Notification from "components/Notification";
import * as Validator from "services/validator";

import useCreateGoal from "./hooks/useCreateGoal";

export default function CreateAccountModal({ close }) {
  const createGoal = useCreateGoal({
    onCreateSuccess: () => {
      close();
      toast.custom((t) => (
        <Notification
          toast={t}
          variant="success"
          content={
            <p className="text-sm font-medium text-gray-900">
              Goal has been added successfully!
            </p>
          }
        />
      ));
    },
  });

  return (
    <section aria-labelledby="create-porfolio-form">
      <form onSubmit={createGoal.handleSubmit}>
        <div className="p-6 bg-white">
          <div>
            <h2
              id="create-porfolio-form-heading"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              Add Goal
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              Create your investment goal.
            </p>
          </div>

          <div className="grid grid-cols-4 gap-6 mt-6">
            <div className="col-span-4">
              <label
                htmlFor="title"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                Title
              </label>
              <FormField
                name="title"
                type="text"
                controlType="input"
                control={createGoal.form.control}
                rules={{ required: true }}
                id="title"
                autoFocus={true}
                placeholder="Enter goal title"
              />
            </div>
            <div className="col-span-4">
              <label
                htmlFor="interestRate"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                Target Amount
              </label>
              <FormField
                name="targetAmount"
                type="number"
                controlType="currency"
                className="hideArrow"
                control={createGoal.form.control}
                rules={{
                  validate: (v) =>
                    (v && Validator.validAmount(v.value)) ||
                    "Enter valid value",
                }}
                id="targetAmount"
                placeholder="e.g 5000000"
              />
            </div>

            <div className="col-span-4">
              <label
                htmlFor="currentValue"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                Target Date
              </label>

              <FormField
                name="targetDate"
                type="text"
                controlType="date"
                control={createGoal.form.control}
                rules={{ required: true }}
                id="targetDate"
                placeholder="e.g 12/12/2025"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end px-4 py-3 text-right bg-gray-50 sm:px-6">
          <div className="mr-3">
            <Button type="button" variant="secondary" onClick={close}>
              Cancel
            </Button>
          </div>
          <div>
            <Button
              type="submit"
              loading={createGoal.form.formState.isSubmitting}
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </section>
  );
}

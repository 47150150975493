import React from "react";
import toast from "react-hot-toast";

import FormField from "components/Form/Field";
import Button from "components/Button";
import Notification from "components/Notification";

import useEditAccount from "./hooks/useEditAccount";

export default function EditAccountModal({ close, account }) {
  const editAccount = useEditAccount({
    account,
    onCreateSuccess: () => {
      close();
      toast.custom((t) => (
        <Notification
          toast={t}
          variant="success"
          content={
            <p className="text-sm font-medium text-gray-900">
              Account has been updated successfully.
            </p>
          }
        />
      ));
    },
  });

  return (
    <section aria-labelledby="create-porfolio-form">
      <form onSubmit={editAccount.handleSubmit}>
        <div className="p-6 bg-white">
          <div>
            <h2
              id="create-porfolio-form-heading"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              Edit Account
            </h2>
          </div>

          <div className="grid grid-cols-4 gap-6 mt-6">
            <div className="col-span-4">
              <label
                htmlFor="name"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <FormField
                name="name"
                type="text"
                controlType="input"
                control={editAccount.form.control}
                rules={{ required: true }}
                id="name"
                autoFocus={true}
                placeholder="Enter name (e.g Crypto)"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end px-4 py-3 text-right bg-gray-50 sm:px-6">
          <div className="mr-3">
            <Button
              type="button"
              variant="secondary"
              onClick={close}
              minWidth={110}
            >
              Cancel
            </Button>
          </div>
          <div>
            <Button
              type="submit"
              loading={editAccount.form.formState.isSubmitting}
              minWidth={110}
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </section>
  );
}

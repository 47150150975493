import React, { useState } from "react";
import { ExclamationIcon, CheckCircleIcon } from "@heroicons/react/solid";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";

import Modal from "components/Modal";

import RecordInvestmentUpdate from "containers/Investment/Header/RecordUpdateModal";
import RecordAccountUpdate from "containers/Account/Header/RecordUpdateModal";
import useUpdateNotifications from "./hooks/useUpdateNotifications";

export default function UpdateNotificationsCard() {
  const { investmentToBeUpdated, accountToBeUpdated, visible } =
    useUpdateNotifications();
  const [showInvestmentUpdateModal, setShowInvestmentUpdateModal] =
    useState(false);
  const [showAccountUpdateModal, setShowAccountUpdateModal] = useState(false);

  if (!visible) {
    return null;
  }

  return (
    <div>
      <div className="flex flex-col px-6 space-y-4">
        {investmentToBeUpdated ? (
          <>
            <div className="p-3 rounded-md bg-red-50">
              <div className="flex mb-1">
                <div className="flex-shrink-0">
                  <ExclamationIcon
                    className="w-6 h-6 text-red-500"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <div className="text-sm text-red-700">
                    <p>
                      <strong>{investmentToBeUpdated.name}</strong> investment
                      has not been updated since{" "}
                      <strong>
                        {formatDistanceToNowStrict(
                          new Date(investmentToBeUpdated.entries[0].timestamp)
                        )}
                      </strong>
                      .
                    </p>
                  </div>
                  <div className="mt-4">
                    <div className="-my-1.5 flex lg:justify-end lg:mx-2">
                      <button
                        type="button"
                        className="bg-red-100 px-2 py-1.5 rounded-md text-sm font-medium text-red-800 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                        onClick={() => setShowInvestmentUpdateModal(true)}
                      >
                        Update Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              open={showInvestmentUpdateModal}
              onClose={() => setShowInvestmentUpdateModal(false)}
              content={
                <RecordInvestmentUpdate
                  investment={investmentToBeUpdated}
                  close={() => setShowInvestmentUpdateModal(false)}
                />
              }
            />
          </>
        ) : null}
        {accountToBeUpdated ? (
          <>
            <div className="p-3 rounded-md bg-red-50">
              <div className="flex mb-1">
                <div className="flex-shrink-0">
                  <ExclamationIcon
                    className="w-6 h-6 text-red-500"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <div className="text-sm text-red-700">
                    <p>
                      <strong>{accountToBeUpdated.name}</strong> account has not
                      been updated since{" "}
                      <strong>
                        {formatDistanceToNowStrict(
                          new Date(accountToBeUpdated.entries[0].timestamp)
                        )}
                      </strong>
                    </p>
                  </div>
                  <div className="mt-4">
                    <div className="-my-1.5 flex lg:justify-end lg:mx-2">
                      <button
                        type="button"
                        className="bg-red-100 px-2 py-1.5 rounded-md text-sm font-medium text-red-800 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                        onClick={() => setShowAccountUpdateModal(true)}
                      >
                        Update Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              open={showAccountUpdateModal}
              onClose={() => setShowAccountUpdateModal(false)}
              content={
                <RecordAccountUpdate
                  account={accountToBeUpdated}
                  close={() => setShowAccountUpdateModal(false)}
                />
              }
            />
          </>
        ) : null}
        {!investmentToBeUpdated && !accountToBeUpdated ? (
          <div className="p-3 rounded-md bg-green-50">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <CheckCircleIcon
                  className="text-green-400 w-7 h-7"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p className="text-sm text-green-800">
                  <strong>You're doing great!</strong> All investments and
                  accounts are upto date.
                </p>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useAuthProvider } from "components/AuthProvider";

export default function useSignin() {
  const navigate = useNavigate();
  const authProvider = useAuthProvider();
  const form = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      try {
        await authProvider.signIn(data);
        navigate("/");
      } catch (err) {
        console.log(err, "error in signin!");
      }
    },
    [navigate, authProvider]
  );

  return {
    form,
    handleSubmit: form.handleSubmit(onSubmit),
  };
}

export const INVESTMENTS_COLOR_PALLETTE = [
  "rgb(235, 211, 75)",
  "rgb(235, 176, 67)",
  "rgb(227, 114, 60)",
  "rgb(178, 68, 42)",
  "rgb(181, 55, 61)",
  "rgb(169, 56, 107)",
  "rgb(172, 94, 157)",
  "rgb(137, 95, 162)",
  "rgb(94, 139, 196)",
  "rgb(91, 187, 181)",
  "rgb(125, 188, 116)",
  "rgb(170, 203, 84)",
];

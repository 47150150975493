import { addDoc, collection, doc, setDoc, deleteDoc } from "firebase/firestore";
import { firebaseStore } from "services/firebase";

export async function create(data) {
  const collectionRef = collection(firebaseStore, "expenses");
  return await addDoc(collectionRef, data);
}

export async function update(expenseId, data) {
  const docRef = doc(firebaseStore, "expenses", expenseId);
  return await setDoc(docRef, data, { merge: true });
}

export async function remove(expenseId) {
  const docRef = doc(firebaseStore, "expenses", expenseId);
  return await deleteDoc(docRef);
}

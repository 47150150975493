import React from "react";

import { useAuthProvider } from "components/AuthProvider";
import { convert as convertAmount } from "services/accountant";

export default function Currency({ value, ...rest }) {
  const authProvider = useAuthProvider();
  const {
    settings: { currency: preferredCurrency },
  } = authProvider;

  const { currency, value: amount } = value;

  let convertedAmount = amount;
  if (
    preferredCurrency !== currency &&
    authProvider.exchangeRates &&
    authProvider.exchangeRates.data
  ) {
    convertedAmount = convertAmount({
      fromCurrency: currency,
      fromValue: Number(amount),
      toCurrency: preferredCurrency,
      rates: authProvider.exchangeRates.data,
    });
  }

  return (
    <span {...rest}>
      {Number(convertedAmount).toLocaleString(
        authProvider.settings.locale || navigator.language,
        {
          style: "currency",
          currency: preferredCurrency,
          maximumFractionDigits: 0,
        }
      )}
    </span>
  );
}

import React, { useState } from "react";
import classNames from "classnames";
import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/solid";

import Currency from "components/Currency";
import useInvestmentStats from "./hooks/useInvestmentStats";

export default function InvestmentStatsCard({ investment }) {
  const [filterOption, setFilterOption] = useState(FILTER_OPTIONS[0].value);
  const stats = useInvestmentStats({
    investment,
    compareRangeInDays: filterOption,
  });

  return (
    <div className="flex-col overflow-hidden bg-white border rounded-sm shadow-sm border-gray-50">
      <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
        <div className="flex flex-wrap items-center justify-between -mt-2 -ml-4 sm:flex-nowrap">
          <div className="mt-2 ml-4">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Overview
            </h3>
          </div>
          <select
            id="investmentStatsFilter"
            name="investmentStatsFilter"
            className="mt-2 cursor-pointer block py-0.5 pl-2 pr-8 text-sm border-none bg-transparent rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            value={filterOption}
            onChange={(e) => setFilterOption(e.target.value)}
          >
            {FILTER_OPTIONS.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <dl className="grid grid-cols-1 overflow-hidden bg-white divide-y divide-gray-100 rounded-lg shadow md:grid-cols-3 md:divide-y-0 md:divide-x">
        {stats.map((item) => (
          <div key={item.name} className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">{item.name}</dt>
            <dd className="flex items-baseline justify-between mt-1 md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                {item.statType === "currency" ? (
                  <Currency value={item.stat} />
                ) : (
                  item.stat
                )}
                <span className="ml-2 text-sm font-medium text-gray-500">
                  from{" "}
                  {item.statType === "currency" ? (
                    <Currency value={item.previousStat} />
                  ) : (
                    item.previousStat
                  )}
                </span>
              </div>

              {parseFloat(item.change) !== 0 ? (
                <div
                  className={classNames(
                    item.changeType === "increase"
                      ? "bg-green-100 text-green-800"
                      : "bg-red-100 text-red-800",
                    "inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0"
                  )}
                >
                  {item.changeType === "increase" ? (
                    <ArrowSmUpIcon
                      className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <ArrowSmDownIcon
                      className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  )}

                  <span className="sr-only">
                    {item.changeType === "increase" ? "Increased" : "Decreased"}{" "}
                    by
                  </span>
                  {item.change}
                </div>
              ) : null}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}

const FILTER_OPTIONS = [
  { label: "Last 7 days", value: 7 },
  { label: "Last 30 days", value: 30 },
  { label: "Last 3 months", value: 90 },
  { label: "Last 6 months", value: 180 },
  { label: "Last 12 months", value: 365 },
];

import classNames from "classnames";
import { ExclamationCircleIcon, CalendarIcon } from "@heroicons/react/solid";

export default function DateInput({
  field: { onChange, onBlur, name, value, ref },
  fieldState: { invalid, isTouched, isDirty },
  formState: { errors },
  type,
  className,
  ...rest
}) {
  const isInvalid = invalid && isTouched;
  const errorMessage = errors[name]?.message;
  const { readOnly } = rest;

  return (
    <div>
      <div className="relative shadow-sm">
        <input
          name={name}
          type={type || "text"}
          className={classNames(
            "block w-full px-3 py-2 appearance-none focus:outline-none sm:text-sm rounded-md",
            className,
            {
              "pr-10 text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500":
                isInvalid,
              "placeholder-gray-400 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 ":
                !isInvalid && !readOnly,
              "placeholder-gray-400 border border-gray-300 focus:ring-transparent focus:border-gray-300 bg-gray-50 cursor-not-allowed":
                !isInvalid && readOnly,
            }
          )}
          aria-invalid={isInvalid}
          ref={ref}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          {...rest}
        />
        {isInvalid && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <ExclamationCircleIcon
              className="w-5 h-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
        <div
          className={classNames(
            "absolute inset-y-0 right-0 flex items-center pointer-events-none",
            {
              "pr-3": !isInvalid,
              "pr-9": isInvalid,
            }
          )}
        >
          <CalendarIcon className="w-5 h-5 text-gray-500" />
        </div>
      </div>
      {errorMessage && (
        <p className="mt-1 text-xs text-red-600">{errorMessage}</p>
      )}
    </div>
  );
}

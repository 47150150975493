import React from "react";
import toast from "react-hot-toast";

import FormField from "components/Form/Field";
import Button from "components/Button";
import Notification from "components/Notification";
import * as Validator from "services/validator";

import useRecordUpdate from "./hooks/useRecordUpdate";

export default function UpdateInvestmentModal({ close, account }) {
  const recordUpdate = useRecordUpdate({
    account,
    onCreateSuccess: () => {
      close();
      toast.custom((t) => (
        <Notification
          toast={t}
          variant="success"
          content={
            <p className="text-sm font-medium text-gray-900">
              Account update has been recorded successfully.
            </p>
          }
        />
      ));
    },
  });

  return (
    <section aria-labelledby="create-porfolio-form">
      <form onSubmit={recordUpdate.handleSubmit}>
        <div className="p-6 bg-white">
          <div>
            <h2
              id="create-porfolio-form-heading"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              Add Entry
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              Add latest current value of the account.
            </p>
          </div>

          <div className="grid grid-cols-4 gap-6 mt-6">
            <div className="col-span-4">
              <label
                htmlFor="name"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <FormField
                name="name"
                type="text"
                controlType="input"
                control={recordUpdate.form.control}
                rules={{ required: true }}
                readOnly={true}
                id="name"
                autoFocus={true}
                placeholder="Enter name (e.g Crypto)"
              />
            </div>
            <div className="col-span-4">
              <label
                htmlFor="currentValue"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                Current Value
              </label>
              <FormField
                name="currentValue"
                type="number"
                controlType="currency"
                control={recordUpdate.form.control}
                rules={{
                  validate: (v) =>
                    (v && Validator.validAmount(v.value)) ||
                    "Enter valid value",
                }}
                id="currentValue"
                placeholder="e.g 32,456"
              />
            </div>
            <div className="col-span-4">
              <label
                htmlFor="date"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                Date
              </label>
              <FormField
                name="date"
                type="text"
                controlType="date"
                control={recordUpdate.form.control}
                rules={{ required: true }}
                id="date"
                placeholder="Enter date (dd/mm/yyyyy)"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end px-4 py-3 text-right bg-gray-50 sm:px-6">
          <div className="mr-3">
            <Button
              type="button"
              variant="secondary"
              onClick={close}
              minWidth={110}
            >
              Cancel
            </Button>
          </div>
          <div>
            <Button
              type="submit"
              loading={recordUpdate.form.formState.isSubmitting}
              minWidth={110}
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </section>
  );
}

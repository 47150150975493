import React, { Fragment } from "react";
import { Transition } from "@headlessui/react";
import classNames from "classnames";
import hotToast from "react-hot-toast";
import {
  CheckCircleIcon,
  ExclamationIcon,
  XIcon,
} from "@heroicons/react/solid";

export default function Notification({ toast, content, variant = "success" }) {
  const { visible, id } = toast;

  return (
    <Transition
      show={visible}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={classNames(
          "w-full max-w-sm overflow-hidden rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5",
          {
            "bg-red-50": variant === "error",
            "bg-white": variant === "success",
          }
        )}
      >
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              {variant === "success" && (
                <CheckCircleIcon
                  className="w-6 h-6 text-green-400"
                  aria-hidden="true"
                />
              )}
              {variant === "error" && (
                <ExclamationIcon
                  className="w-6 h-6 text-red-400"
                  aria-hidden="true"
                />
              )}
            </div>
            <div
              className={classNames("ml-3 w-0 flex-1 pt-0.5", {
                "text-red-800": variant === "error",
              })}
            >
              {content}
            </div>
            <div className="flex flex-shrink-0 ml-4">
              <button
                className={classNames(
                  "inline-flex rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ",
                  {
                    "text-red-400 bg-red-50 hover:text-red-500 focus:ring-red-500":
                      variant === "error",
                    "text-gray-400 bg-white hover:text-gray-500 focus:ring-indigo-500":
                      variant === "success",
                  }
                )}
                onClick={() => {
                  hotToast.dismiss(id);
                }}
              >
                <span className="sr-only">Close</span>
                <XIcon className="w-5 h-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}

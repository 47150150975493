import classNames from "classnames";
import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/solid";
import { CashIcon } from "@heroicons/react/outline";

import Currency from "components/Currency";
import useNetWorthData from "./hooks/useNetWorthData";

export default function NetWorthCard() {
  const changeType = ["increase", "decrease"][Math.floor(Math.random() * 2)];
  const change = (Math.random() * 20 + Math.random() * 5).toFixed(2);
  const netWorthData = useNetWorthData();

  return (
    <div className="relative px-4 pt-5 overflow-hidden bg-white border-b border-gray-200 sm:pt-6 sm:px-5">
      <dt>
        <div className="absolute p-1.5 bg-green-800 rounded-full">
          <CashIcon className="w-6 h-6 text-white" aria-hidden="true" />
        </div>
        <p className="text-sm font-medium text-gray-400 truncate ml-14">
          Net worth
        </p>
      </dt>
      <dd className="flex items-baseline pb-3 ml-14">
        <p className="text-4xl font-semibold text-gray-900">
          {netWorthData.today ? (
            <Currency value={netWorthData.today.total} />
          ) : null}
        </p>
        {false && (
          <p
            className={classNames(
              changeType === "increase" ? "text-green-600" : "text-red-600",
              "ml-2 flex items-baseline text-sm font-semibold"
            )}
          >
            {changeType === "increase" ? (
              <ArrowSmUpIcon
                className="self-center flex-shrink-0 w-5 h-5 text-green-500"
                aria-hidden="true"
              />
            ) : (
              <ArrowSmDownIcon
                className="self-center flex-shrink-0 w-5 h-5 text-red-500"
                aria-hidden="true"
              />
            )}
            <span className="sr-only">
              {changeType === "increase" ? "Increased" : "Decreased"} by
            </span>
            {change}%
          </p>
        )}
        {false && (
          <div className="absolute inset-x-0 bottom-0 px-4 py-4 bg-gray-50 sm:px-6">
            <div className="text-sm text-right">
              <a
                href="#"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                {" "}
                View details
              </a>
            </div>
          </div>
        )}
      </dd>
      <div className="flex items-center justify-center mb-3">
        <div className="flex flex-col items-start py-1 pr-12 mr-12 border-r border-gray-200">
          <dt className="text-xs text-gray-500 truncate">Investments Value</dt>
          <dd className="mt-0.5 text-lg font-medium text-gray-900">
            {netWorthData.today ? (
              <Currency value={netWorthData.today.investments} />
            ) : null}
          </dd>
        </div>
        <div className="flex flex-col items-start py-1 pr-12">
          <dt className="text-xs text-gray-500 truncate">Savings</dt>
          <dd className="mt-0.5 text-lg font-medium text-gray-900">
            {netWorthData.today ? (
              <Currency value={netWorthData.today.savings} />
            ) : null}
          </dd>
        </div>
      </div>
    </div>
  );
}

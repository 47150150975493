import { useMemo } from "react";
import { Chart } from "react-charts";

import Loaders from "components/Loaders";
import useNetWorthSeries, {
  TIME_RANGE_OPTIONS,
} from "./hooks/useNetWorthSeries";

export default function NetWorthSeriesCard() {
  const netWorthSeries = useNetWorthSeries();

  return (
    <div className="flex-col overflow-hidden bg-white border rounded-sm shadow-sm border-gray-50">
      <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
        <div className="flex flex-wrap items-center justify-between -mt-2 -ml-4 sm:flex-nowrap">
          <div className="flex flex-col items-stretch mt-2 ml-4 space-y-1">
            <h3 className="text-xl font-medium leading-6 text-gray-900">
              Net worth over time
            </h3>
          </div>
          <div className="flex-shrink-0 mt-2 ml-4">
            <select
              id="monthlySeriesCashflowGroupBy"
              name="monthlySeriesCashflowGroupBy"
              className="mb-2 cursor-pointer block py-0.5 ml-auto mx-3 pr-8 text-sm border-none bg-transparent rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              value={netWorthSeries.range.value}
              onChange={(e) =>
                netWorthSeries.setRange(
                  TIME_RANGE_OPTIONS.filter(
                    (o) => o.value === e.target.value
                  )[0]
                )
              }
            >
              {TIME_RANGE_OPTIONS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {netWorthSeries.loading ? (
        <div className="flex justify-center py-12">
          <Loaders.Spinner className="w-6 h-6 text-indigo-600" />
        </div>
      ) : netWorthSeries.data && netWorthSeries.data.length ? (
        <NetWorthChart netWorthSeriesData={netWorthSeries.data} />
      ) : (
        <div className="flex-col py-12 text-center">
          No net worth data empty state here!
        </div>
      )}
    </div>
  );
}

function NetWorthChart({ netWorthSeriesData }) {
  const data = useMemo(
    () => [
      {
        label: "Net Worth",
        data: netWorthSeriesData.map((item) => ({
          primary: item.date,
          secondary: item.value.total.value,
        })),
      },
      {
        label: "Investments Value",
        data: netWorthSeriesData.map((item) => ({
          primary: item.date,
          secondary: item.value.investments.value,
        })),
      },
      {
        label: "Savings",
        data: netWorthSeriesData.map((item) => ({
          primary: item.date,
          secondary: item.value.savings.value,
        })),
      },
    ],
    [netWorthSeriesData]
  );

  const primaryAxis = useMemo(
    () => ({
      getValue: (datum) => datum.primary,
      elementType: "time",
    }),
    []
  );
  const secondaryAxes = useMemo(
    () => [
      {
        getValue: (datum) => datum.secondary,
        elementType: "line",
      },
    ],
    []
  );

  return (
    <div className="m-6" style={{ height: 400 }}>
      <Chart
        options={{
          data,
          primaryAxis,
          secondaryAxes,
        }}
      />
    </div>
  );
}

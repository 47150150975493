import { useState, useCallback } from "react";
import * as ExpenseApis from "apis/expenses";

export default function useExpenseRemove() {
  const [expenseIdsToRemove, setExpenseIdsToRemove] = useState([]);

  return {
    removeExpense: useCallback(
      async ({ expense, onSuccess, onError }) => {
        setExpenseIdsToRemove([...expenseIdsToRemove, expense.id]);

        try {
          await ExpenseApis.remove(expense.id);
          onSuccess();
        } catch (err) {
          onError(err);
        }

        setExpenseIdsToRemove(
          expenseIdsToRemove.filter((id) => id !== expense.id)
        );
      },
      [expenseIdsToRemove]
    ),

    isRemovingExpense: useCallback(
      (expenseId) => {
        return expenseIdsToRemove.indexOf(expenseId) !== -1;
      },
      [expenseIdsToRemove]
    ),
  };
}

import { Outlet, useOutlet } from "react-router-dom";

import InvestmentsCard from "containers/Dashboard/InvestmentsCard";

export default function InvestmentsContainer() {
  const match = useOutlet();

  if (match.props.children) {
    return <Outlet />;
  } else {
    return (
      <div className="flex flex-col space-y-9">
        <InvestmentsCard displayClosedInvestments={true} />
      </div>
    );
  }
}

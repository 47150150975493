import useAccounts from "hooks/data/useAccounts";
import useInvestments from "hooks/data/useInvestments";
import * as Accountant from "services/accountant";

export default function useNetWorthData() {
  const {
    investments,
    loading: investmentsLoading,
    error: investmentsError,
  } = useInvestments();
  const {
    accounts,
    loading: accountsLoading,
    error: accountsError,
  } = useAccounts();

  return {
    today:
      !investmentsLoading && !accountsLoading
        ? Accountant.currentNetWorth({ accounts, investments })
        : null,
    series: [],
    loading: !investmentsLoading && !accountsLoading,
  };
}

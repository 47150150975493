import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { format as formatDate, parse as parseDate } from "date-fns";

import AuthProvider from "components/AuthProvider";
import LayoutContainer from "containers/Layout";
import DashboardContainer from "containers/Dashboard";
import InvestmentsContainer from "containers/Investments";
import AccountsContainer from "containers/Accounts";
import AccountContainer from "containers/Account";
import ReportsContainer from "containers/Reports";
import SettingsContainer from "containers/Settings";
import SigninContainer from "containers/SigninContainer";
import SignupContainer from "containers/SignupContainer";
import SetupContainer from "containers/SetupContainer";
import InvestmentContainer from "containers/Investment";
import CashflowContainer from "containers/Cashflow";
import ToolsContainer from "containers/Tools";

export default function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<LayoutContainer />}>
            <Route index element={<DashboardContainer />} />
            <Route path="/investments" element={<InvestmentsContainer />}>
              <Route path=":investmentId" element={<InvestmentContainer />} />
            </Route>
            <Route path="/accounts" element={<AccountsContainer />}>
              <Route path=":accountId" element={<AccountContainer />} />
            </Route>
            <Route path="/reports" element={<ReportsContainer />} />
            <Route path="/settings" element={<SettingsContainer />} />
            <Route path="/cashflow" element={<CashflowContainer />} />
            <Route path="/tools" element={<ToolsContainer />} />
          </Route>

          <Route path="/setup" element={<SetupContainer />} />
          <Route path="/signup" element={<SignupContainer />} />
          <Route path="/signin" element={<SigninContainer />} />
        </Routes>
        <Toaster position="bottom-center" gutter={10} />
      </AuthProvider>
    </BrowserRouter>
  );
}

window._parseDate = parseDate;
window._formatDate = formatDate;

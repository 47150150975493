import { useCallback, useState } from "react";

export default function useExpandList({
  visible,
  initialExpanded = false,
  onExpand,
  onShrink,
}) {
  const [expanded, setExpanded] = useState(initialExpanded);

  return {
    visible,
    message: expanded ? "Show less" : "Show more",
    toggle: useCallback(() => {
      setExpanded(!expanded);
      if (expanded) {
        onShrink();
      } else {
        onExpand();
      }
    }, [expanded, onExpand, onShrink]),
  };
}

import classNames from "classnames";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

export default function CustomLink({
  children,
  to,
  activeClassName,
  inactiveClassName,
  className,
  ...props
}) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });
  const finalClassName = classNames(className, {
    [activeClassName]: Boolean(match),
    [inactiveClassName]: !Boolean(match),
  });

  return (
    <Link to={to} className={finalClassName} {...props}>
      {typeof children === "function" ? children({ match }) : children}
    </Link>
  );
}

import { useEffect, useState } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import deepMerge from "deepmerge";

import { firebaseStore } from "services/firebase";
import { _set } from "services/lodash";
import * as Accountant from "services/accountant";

export default function useUserSettings({ user }) {
  const [userSettings, setUserSettings] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function init() {
      if (user) {
        const docRef = doc(firebaseStore, "users", user.uid);
        const snap = await getDoc(docRef);

        if (snap.exists()) {
          const snapData = snap.data();
          Accountant.setContext({ preferredCurrency: snapData.currency });
          setUserSettings(deepMerge(DEFAULT_SETTINGS, snapData));
        } else {
          await setDoc(doc(firebaseStore, "users", user.uid), DEFAULT_SETTINGS);
          Accountant.setContext({
            preferredCurrency: DEFAULT_SETTINGS.currency,
          });
          setUserSettings(DEFAULT_SETTINGS);
        }

        setLoading(false);
      }
    }

    init();
  }, [user]);

  async function update(path, value) {
    _set(userSettings, path, value);
    return setDoc(doc(firebaseStore, "users", user.uid), userSettings);
  }

  return { settings: userSettings, update, loading };
}

const DEFAULT_SETTINGS = {
  currency: "INR",
  cards: {
    investmentsDashboard: {
      sortKey: "currentValue",
      sortDesc: true,
    },
    investmentEntries: {
      sortKey: "date",
      sortDesc: true,
    },
    accountEntries: {
      sortKey: "date",
      sortDesc: true,
    },
    accountsDashboard: {
      sortKey: "currentValue",
      sortDesc: true,
    },
    expensesList: {
      sortKey: "date",
      sortDesc: true,
    },
    incomesList: {
      sortKey: "date",
      sortDesc: true,
    },
  },
};

// TODO Add prop to control visibility of "App initialization taking too long, try logging-in"
export default function AppShell() {
  return (
    <div className="flex items-center justify-center w-full h-full bg-gray-50">
      <span className="relative flex w-6 h-6">
        <span className="absolute inline-flex w-full h-full bg-indigo-400 rounded-full opacity-75 animate-ping"></span>
        <span className="relative inline-flex w-6 h-6 bg-indigo-500 rounded-full"></span>
      </span>
    </div>
  );
}

import React from "react";
import { useParams, Navigate } from "react-router-dom";

import Loaders from "components/Loaders";

import Header from "./Header";
import StatsCard from "./StatsCard";
import EntriesCard from "./EntriesCard";
import useInvestment from "./hooks/useInvestment";

export default function Investment(props) {
  const { investmentId } = useParams();
  const { value: investment, loading } = useInvestment({ id: investmentId });

  return (
    <div className="flex flex-col pb-12">
      {loading ? (
        <Loaders.Spinner className="w-6 h-6 my-6 text-indigo-600" />
      ) : investment ? (
        <div className="flex flex-col space-y-6">
          <Header investment={investment} />
          <StatsCard investment={investment} />
          <EntriesCard investment={investment} />
        </div>
      ) : (
        <Navigate to="/" replace={true} />
      )}
    </div>
  );
}
